import React from 'react'

const UtilizationStats = () => (
  <div className="card">
    <h5 className="card-header">Utilization</h5>
    <div className="card-body">
      <p className="text-center" style={{ fontSize: '200%' }}>
        10 <span className="text-muted">%</span>
      </p>
      <a href="#" className="card-link">Show more</a>
    </div>
  </div>
)

export default UtilizationStats
