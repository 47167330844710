import React, { Component } from 'react'
import { Map as GoogleMap, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react'
import { withApollo } from 'react-apollo'
import query from './query.graphql'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import flowRight from 'lodash/flowRight'

export class Map extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    center: {
      lat: 30.266666,
      lng: -97.733330
    },
    markers: []
  }
  map = null
  zoom = 13

  async componentDidUpdate (prevProps, prevState, snapshot) {
    if (!isEqual(this.props.tags, prevProps.tags) || (this.props.query !== prevProps.query)) {
      await this.readMarkers(null, this.map)
    }
  }

  readMarkers = async (_, map) => {
    this.map = map
    const { tags, query: queryString } = this.props
    const { data: { assets } } = await this.props.client.query({ query, variables: { tags, query: queryString } })
    const bounds = new this.props.google.maps.LatLngBounds()
    const markers = []
    assets.map(asset => ({
      ...asset,
      geolocation: {
        lng: parseFloat(get(asset, 'geolocation.lng')),
        lat: parseFloat(get(asset, 'geolocation.lat'))
      }
    }))
      .forEach(({ device, geolocation, id, name }) => {
        if (geolocation && (geolocation.lng && geolocation.lat)) {
          bounds.extend(geolocation)
          markers.push(
            <Marker
              key={id}
              modemNumber={get(device, 'modemNumber')}
              onClick={this.onMarkerClick}
              id={id}
              title={name}
              name={name}
              position={geolocation}
            />
          )
        }
      })
    this.setState({ markers })
    if (bounds || bounds.length) {
      this.map.fitBounds(bounds)
    }
  }

  render () {
    return (
      <GoogleMap
        onReady={this.readMarkers}
        google={this.props.google}
        zoom={this.zoom}
        initialCenter={this.state.center}
      >
        {this.state.markers}
        <InfoWindow
          key="info-window"
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h5>{this.state.selectedPlace.name}</h5>
            <dl>
              <dt>Device</dt>
              <dd>{this.state.selectedPlace.modemNumber}</dd>
            </dl>
            <a href={`/asset-tracking/${this.state.activeMarker.id}`}>
              Details
            </a>
          </div>
        </InfoWindow>
      </GoogleMap>
    )
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    })

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  }
}

export default flowRight(
  GoogleApiWrapper({
    apiKey: 'AIzaSyBw36MVCdLGxMC1Q7WKFDK_XqWCbqF7NRs'
  }),
  withApollo
)(Map)
