import React, { Fragment } from 'react'
import ContentWrapper from '../ContentWrapper'
import hardPicOne from './hardPicOne.jpg'
import './index.less'

const HardwareFeat = () => (
  <Fragment>
    <ContentWrapper>
      <div className="bg-hard-feat">
        <h1 className="h1-landing display-3 text-light text-center p-5">Hardware Features</h1>
        <div className="header-hardware">
          <hr className="line-center" />
          <h2 className="h2-feat text-secondary text-center">General Info</h2>
        </div>
        <div className="hardware-top">
          <div className="hard-p-one">
            <p className="p-landing">
            The EcoTrack provides up to 5 years of battery life and was built to withstand extreme conditions and environments (water, heat & cold resistant).
            It’s easy to install, easy to operate and provides quality global equipment monitoring at a practical price.
            EcoTrack works off a simple stick and track solutions. No wiring, no mechanical installs.
            The battery life holds up to 10 years of charge, ensuring you can deploy EcoTrack into the field quickly and easily, without having to worry about battery charge, replacement, and availability of sunlight for solar recharging.
            </p>
          </div>
          <div className="hard-pic-one">
            <img className="img-fluid rounded" src={hardPicOne} alt=""/>
          </div>
        </div>
        <div className="hardware-middle">
          <h2 className="h2-feat text-secondary ml-4">Key Features</h2>
          <ul className="key-feat">
            <li>Up to 10 years of Battery Life</li>
            <li>Global Coverage</li>
            <li>Quick Installation (Stick and Track)</li>
            <li>Durability in Extreme Weather (Water, Heat & Cold resistant)</li>
            <li>Powered Separately From Electrical System</li>
          </ul>
        </div>
        <h2 className="h2-spec text-center text-light">Specifications</h2>
        <div className="hardware-bottom">
          <div className="battery">
            <h3 className="key-feat">Battery Type:</h3>
            <ul className="pl-4">
              <li>2 Cell 10 Year Battery</li>
            </ul>
          </div>
          <div className="temp">
            <h3 className="key-feat">Operating Temperature:</h3>
            <ul className="pl-4">
              <li>Cold temp max </li>
              <li>Heat max temp</li>
              <li>Water Resistant up to 30 Feet</li>
            </ul>
          </div>
          <div className="dim">
            <h3 className="key-feat">Dimentions:</h3>
            <ul className="pl-4">
              <li>Height</li>
              <li>Length</li>
              <li>Width</li>
            </ul>
          </div>
          <div className="weight">
            <h3 className="key-feat">Weight:</h3>
            <ul className="pl-4">
              <li>1 lbs</li>
            </ul>
          </div>
          <div className="certs">
            <h3 className="key-feat">Certifications & Standards:</h3>
            <ul className="pl-4">
              <li>OSHA</li>
              <li>FCC</li>
            </ul>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </Fragment>
)

export default HardwareFeat
