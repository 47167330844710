import React, { Component } from 'react'
import { notify } from './components/Notifications'
import Footer from './components/Footer'
import SignUpView from './views/SignUp'
import SignInView from './views/SignIn'
import Settings from './components/Settings'
import AssetsView from './views/Assets'
import flowRight from 'lodash/flowRight'

import { withApollo } from 'react-apollo'
import { withRouter, Switch } from 'react-router-dom'
import HomeView from './views/Home'
import ResetView from './views/Reset'
import ResetConfirmationView from './views/ResetConfirmation'
import ContactFormView from './views/ContactForm'
import PrivacyPolicyView from './views/PrivacyPolicy'
import TermsOfUseView from './views/TermsOfUse'
import AboutUsView from './views/AboutUs'
import FAQView from './views/FAQ'
import SupportView from './views/Support'
import UserContext from './lib/user-context'
import SearchContext from './lib/searchContext'
import URLS from './config/urls'
import DashboardView from './views/Dashboard'
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'
import AssetTracking from './components/AssetTracking'
import Logistics from './components/LandingPage-Logistics'
import Construction from './components/LandingPage-Construction'
import ShippingContainer from './components/LandingPage-ShippingContainer'
import Leasing from './components/LandingPage-Leasing'
import Financing from './components/LandingPage-Financing'
import NotFound from './components/NotFound'
import HardwareFeat from './components/FeaturesHardware'
import SoftwareFeat from './components/FeaturesSoftware'
import PricingPage from './components/PricingPage'
import meQuery from './me.graphql'
import './index.css'
import Insurance from './components/LandingPage-Insurance'

class App extends Component {
  state = {
    user: undefined,
    tags: [],
    query: ''
  }

  render () {
    return (
      <UserContext.Provider value={{ user: this.state.user, signIn: this.signIn, signOut: this.signOut }}>
        <SearchContext.Provider value={{ tags: this.state.tags, query: this.state.query, onSetTags: this.onSetTags, onSetQuery: this.onSetQuery }}>
          <main role="main">
            <Switch>
              <PublicRoute exact path={URLS.HOME} component={HomeView} />
              <PublicRoute exact path={URLS.SIGN_UP} component={SignUpView} />
              <PublicRoute exact path={URLS.SIGN_IN} component={SignInView} />
              <PublicRoute exact path={URLS.RESET_PASSWORD} component={ResetView} />
              <PublicRoute exact path={URLS.RESET_PASSWORD_CODE} component={ResetConfirmationView} />
              <PublicRoute exact path={URLS.CONTACT} component={ContactFormView} />
              <PublicRoute exact path={URLS.PRIVACY} component={PrivacyPolicyView} />
              <PublicRoute exact path={URLS.TERMS} component={TermsOfUseView} />
              <PublicRoute exact path={URLS.ABOUT_US} component={AboutUsView} />
              <PublicRoute exact path={URLS.SUPPORT} component={SupportView} />
              <PublicRoute exact path={URLS.FAQ} component={FAQView} />
              <PublicRoute exact path={URLS.LOGISTICS} component={Logistics} />
              <PublicRoute exact path={URLS.CONSTRUCTION} component={Construction} />
              <PublicRoute exact path={URLS.SHIPPING_CONTAINER} component={ShippingContainer} />
              <PublicRoute exact path={URLS.LEASING} component={Leasing} />
              <PublicRoute exact path={URLS.HARDWARE_FEATURES} component={HardwareFeat} />
              <PublicRoute exact path={URLS.SOFTWARE_FEATURES} component={SoftwareFeat} />
              <PublicRoute exact path={URLS.FINANCING} component={Financing} />
              <PublicRoute exact path={URLS.PRICING} component={PricingPage} />
              <PublicRoute exact path={URLS.INSURANCE} component={Insurance} />
              <PrivateRoute exact path={URLS.ASSET_TRACKING} component={AssetTracking} />
              <PrivateRoute exact path={URLS.SETTINGS} component={Settings} />
              <PrivateRoute exact path={URLS.DASHBOARD} component={DashboardView} />
              <PrivateRoute exact path={URLS.ASSETS} component={AssetsView} />
              <NotFound />
            </Switch>
          </main>
          <Footer />
        </SearchContext.Provider>
      </UserContext.Provider>
    )
  }

  componentDidMount () {
    this.props.client.query({
      query: meQuery,
      fetchPolicy: 'network-only'
    }).then(() => {
      this.setState({ user: true })
    }).catch(e => {
      this.setState({ user: false })
    })
  }

  onSetTags = tags => this.setState({ tags })

  onSetQuery = query => this.setState({ query })

  signIn = ({ token }) => {
    const { client, history } = this.props
    notify('You are now signed in.', 'success')
    window.localStorage.setItem('token', token)
    this.setState({ user: true })
    client.resetStore().then(() => {
      history.push(URLS.DASHBOARD)
    })
  }

  signOut = () => {
    const { client, history } = this.props
    window.localStorage.removeItem('token')
    this.setState({ user: false })
    client.resetStore().then(() => {
      notify('Signed out!')
      history.push('/')
    })
  }

  onError = e => {
    notify(`Application Error: ${e.toString()}`)
  }
}

export default flowRight(
  withApollo,
  withRouter
)(App)
