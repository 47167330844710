import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { ContentWrapper, Button } from '../index'
import './index.less'
import URLS from '../../config/urls'

class PricingCard extends Component {
  state = {
    assetsCount: 1
  }

  price = 10

  rangeValues = {
    min: 1,
    max: 30
  }

  onChangeCount = ({ target: { value } }) => this.setState({ assetsCount: Number(value) })

  onRedirect = path => {
    this.props.history.push(path)
  }

  calculatePrice = () => {
    const { assetsCount } = this.state
    return assetsCount * this.price
  }

  render () {
    const { assetsCount } = this.state
    return (
      <ContentWrapper>
        <div className="pricing-card card shadow-sm">
          <div className="card-body">
            <h3>Customized to your needs</h3>
            <small>PRICING PER DEVICE AND SUBSCRIPTION PER MONTH</small>
            <h2 className="price">{this.price}$</h2>
            <div className="input-card">
              <input
                min={this.rangeValues.min}
                max={this.rangeValues.max}
                type="range"
                onChange={this.onChangeCount}
                className="custom-range"
                value={assetsCount}
              />
              <div className="details">
                {assetsCount === this.rangeValues.max ? (
                  <Fragment>
                    <div className="description">
                      More than <span>{this.rangeValues.max}</span> Assets? Contact us to discuss the best option
                      for you.
                    </div>
                    <Button label="Contact us" onClick={() => this.onRedirect(URLS.CONTACT)} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="description">
                      <span>{assetsCount}</span> Assets =  <span>{this.calculatePrice()}$</span> /mo
                    </div>
                    <Button label="Start tracking" onClick={() => this.onRedirect(URLS.SIGN_UP)} />
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    )
  }
}

export default withRouter(PricingCard)
