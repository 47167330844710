import Input from '../Input'
import React, { Fragment } from 'react'

const ResetForm = ({ errors, onSubmit, onChange, email, onKeyPress }) => (
  <Fragment>
    <form className="Form" onSubmit={onSubmit}>
      <Input error={errors.email} type="email" onKeyPress={onKeyPress} helptext="Further instructions will be send to your email address." value={email} onChange={onChange('email')} label="Email Address" />
      <p className="Form__Buttons">
        <button className="mt-2 btn btn-primary">Request Password Reset</button>
      </p>
    </form>
  </Fragment>
)

export default ResetForm
