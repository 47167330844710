import React, { Component, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import classNames from 'classnames'
import { Badge, EditableBlock, DeletableBlock } from '../index'
import tagsQuery from '../Tags/tags.graphql'
import {
  TAGS_LENGTH_ERROR_MESSAGE,
  DEFAULT_ERROR_TIMEOUT,
  TITLE_DELETE,
  TITLE_EDIT,
  INLINE_OPERATOR_MODES
} from '../../config/constants'
import './index.less'

const { EDITABLE, DELETABLE } = INLINE_OPERATOR_MODES

class Tag extends Component {
  state = {
    busy: false,
    name: this.props.name,
    mode: ''
  }

  onChange = name => {
    if (name.length > 0) {
      this.setState({ name })
    } else {
      this.setState({ error: TAGS_LENGTH_ERROR_MESSAGE })
      setTimeout(() => this.setState({ error: '' }), DEFAULT_ERROR_TIMEOUT)
    }
  }

  onRemove = async () => {
    const { busy } = this.state
    const { id, removeMutation, tagType } = this.props
    if (!busy) {
      this.setState({ busy: true })
      await removeMutation({
        variables: { id },
        refetchQueries: [{ query: tagsQuery, variables: { tagType } }]
      })
      this.setState({ busy: false })
    }
  }

  onUpdate = async () => {
    const { name, busy } = this.state
    const { id, updateMutation, tagType, name: originTagName } = this.props
    if (!busy && originTagName !== name) {
      this.setState({ busy: true })
      await updateMutation({
        variables: { id, name, tagType }
      })
      this.setState({ busy: false, mode: '' })
    }
    this.setState({ mode: '' })
  }

  onToggleMode = (e, modeName) => {
    const { name } = this.props
    const { mode } = this.state
    if (mode === modeName) {
      this.setState({ mode: '', name })
    } else {
      this.setState({ mode: modeName })
    }
  }

  render () {
    const { name, mode, busy } = this.state
    return (
      <div className={classNames('tag', { 'inline-operator': !!mode })}>
        {mode === EDITABLE && (
          <EditableBlock
            value={name}
            onEdit={this.onUpdate}
            onChange={this.onChange}
            busy={busy}
            onToggleMode={this.onToggleMode}
            dataTest="tagEditableInput"
          />
        )}
        {mode === DELETABLE && (
          <DeletableBlock
            busy={busy}
            onDelete={this.onRemove}
            onToggleMode={this.onToggleMode}
            deleteTitle={`${TITLE_DELETE} Tag`}
            deleteQuestion="Are you sure you want to remove this tag?"
          />
        )}
        {!mode && (
          <Fragment>
            <Badge label={name} />

            <span
              className="primary-icon"
              onClick={e => this.onToggleMode(e, EDITABLE)}
              title={TITLE_EDIT}
            >
              <FontAwesomeIcon icon={faEdit} />
            </span>
            <span
              className="primary-icon"
              onClick={e => this.onToggleMode(e, DELETABLE)}
              title={TITLE_DELETE}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          </Fragment>
        )}
      </div>
    )
  }
}

export default Tag
