import React from 'react'
import ResetConfirmation from '../components/ResetConfirmation'
import ContentWrapper from '../components/ContentWrapper'

const ResetConfirmationView = () => (
  <ContentWrapper>
    <div className="row justify-content-md-center">
      <div className="col col-md-7 col-lg-6">
        <h1 className="display-4">Change your password</h1>
        <ResetConfirmation />
      </div>
    </div>
  </ContentWrapper>
)

export default ResetConfirmationView
