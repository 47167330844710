import React from 'react'
import PricingCard from '../PricingCard'
import { ContentWrapper } from '../index'

const PricingPage = () => (
  <ContentWrapper>
    <h1 className="display-4 text-center">Pricing</h1>
    <PricingCard />
  </ContentWrapper>
)

export default PricingPage
