import React, { Fragment } from 'react'
import ContentWrapper from '../ContentWrapper'
import SeoContent from '../SeoContent'
import URLS from '../../config/urls'
import { Link } from 'react-router-dom'
import logo from './logo.png'
import shipPicOne from './shipPicOne.jpeg'
import shipPicThree from './shipPicThree.jpeg'
import shipBigPic from './shipBigPic.jpeg'
import './index.less'

const APP_URL = process.env.REACT_APP_BASE_URL
const seo = {
  title: 'Shipping Container GPS Tracking | Intermodal Tracking | EcoTrack',
  description:
    'Battery powered stick & track GPS system for Shipping Container Intermodals - Track all intermodals without breaking the piggy bank. Shipping Containers can move from ships to trains to trucks. EcoTrack, makes sure they don’t skip any steps and arrive where they need to be, when they need to be there. EcoTrack has you covered by land, air and sea.',
  url: `${APP_URL}${URLS.SHIPPING_CONTAINER}/`,
  image: APP_URL + logo
}

const ShippingContainer = () => (
  <Fragment>
    <ContentWrapper>
      <SeoContent {...seo} />
      <div className="bg-shipping">
        <h1 className="display-3 text-light text-center h1-landing">Shipping Container</h1>
      </div>
      <hr className="line-center" />
      <h2 className="h2-landing text-secondary">Intermodal Shipping Container Tracking for Maritime</h2>
      <p className="p-landing text-center mt-4">
      Shipping Containers can move from ships to trains to trucks.<br/>EcoTrack makes sure they don’t
      skip any steps and arrive where they need to be, when they need to be there.
      </p>
      <div className="shipping-one">
        <div className="ship-pic-one">
          <img className="img-fluid rounded" src={shipPicOne} />
        </div>
        <div className="ship-cont">
          <h3 className="h3-landing px-5 text-dark">Wherever your shipping container goes, we go too.</h3>
          <p className="p-landing">
          Through EcoTrack, you’re able to not only use its low cost container tracking satellite technology to monitor the locations of your shipping containers and cargo,
          but you’re also able to calculate like dormancy rate (how long a container or shipment has sat dormant and where) or utilization rate
          (how often the asset is in transport and moving) enabling you to track fundamentally important fleet data points.</p>
        </div>
      </div>
      <h3 className="h3-landing text-dark">Battery Powered - Shipping Container GPS - Intermodal Tracking</h3>
      <p className="p-landing">
      By using the EcoTrack dashboard, you’re also able to track tasks & reminders, retrieve container and asset locations from anywhere with ease, group categories or equipment,
      and track utilization and dormancy with EcoTrack’s simple GPS Fleet Management System.
      We want to make sure that any under-utilized assets are repurposed to increase revenue instead of gathering dust.
      </p>
      <div className="shipping-two">
        <div className="ship-com">
          <h3 className="h3-landing text-dark">Communication</h3>
          <p className="p-landing">
          While teamwork is paramount in a logistics focused company, communication is the thread that holds teams and companies together. By using the EcoTrack line of logistics tracking solutions,
          you have the ability to share data related to specific assets with select 3rd parties at your fingertips.
          Share data with the teams or subcontractors that need it most by grouping and tagging your inventory based on who needs visibility.
          </p>
        </div>
        <div className="ship-price-div">
          <div className="ship-pic-two">
            <h2 className="h2-button">Get started with EcoTrack</h2>
            <Link to={URLS.PRICING} className="btn btn-light price-button">Check our prices</Link>
          </div>
        </div>
      </div>
      <h3 className="h3-landing text-dark">Maritime Asset Tracker</h3>
      <p className="p-landing">
      One important part of using an EcoTrack GPS Maritime Asset Tracker is providing one more piece of safety equipment for our customers and their employees. Incase of emergency,
      your cargo or the vessel carrying it can easily be located with an increased ability to provide all necessary assistance quicker and more efficiently than ever.
      Even if a shipping container is stolen, our Geofencing features will alert all necessary parties immediately, allowing for a quick response.
      </p>
      <div className="ship-big-pic-div">
        <img className="big-picture rounded" src={shipBigPic} alt=""/>
      </div>
      <div className="shipping-three">
        <div className="ship-pic-three">
          <img className="img-fluid rounded" src={shipPicThree} />
        </div>
        <div className="ship-stick">
          <h3 className="h3-landing px-5 text-dark">Stick and Track - No headache simple setup.</h3>
          <p className="p-landing">
          By using two cell and two way batteries in our hardware packs, customers receive an EcoTracker with a battery will provide up to 10 years of service in almost any condition you could put it through.
          We believe adaptability is one of the cornerstones of a successful business and that’s why we provide our customers with a product that can operate
          independent of a power source. This way the EcoTrack will be providing data no matter what happens to vessel or is electrical system while in transit.
          Whether you track one small asset group or a fleet of maritime vessels, we have you and your logistics company covered.</p>
        </div>
      </div>
      <h3 className="h3-landing text-dark">Best tracking solution</h3>
      <p className="p-landing">
      Our goal with EcoTrack is to decrease dormancy rates and increase utilization for every one of our customers, across every asset class. That’s why our product enables companies to track
      their different asset types including: maritime asset tracking, vessel management software, container asset tracking, shipping container asset tracking and international freight tracking.
      At base level, EcoTrack is a tool that was designed to help companies and their employees manage their assets easier, at a price that wouldn’t break their budget.
      So browse our newest technology and find a GPS tracking solution that works best for you.</p>
      <div className="middle-page-btn">
        <Link to={URLS.SIGN_UP} className="btn btn-primary btn-lg p-3">Get started with EcoTrack</Link>
      </div>
    </ContentWrapper>
  </Fragment>
)

export default ShippingContainer
