import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import URLS from '../../config/urls'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import './index.less'

export default class Wizard extends Component {
  localStorageKey = 'welcomeWizardClosed'
  constructor (props) {
    super(props)
    this.state = {
      open: window.localStorage.getItem(this.localStorageKey) !== 'true'
    }
  }

  handleClose = (reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ open: false })
    window.localStorage.setItem(this.localStorageKey, 'true')
  }

  render () {
    if (!this.state.open) {
      return null
    }

    return (
      <div className="jumbotron">
        <div className="wizardHeader" data-cy="wizardHeader">
          <h1 className="display-4">Getting started</h1>
          <button onClick={this.handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <p className="lead">Now it&apos;s time to add your first asset to track. Make sure you have your Modem Number prepared. </p>
        <hr className="my-4" />
        <p className="lead">It doesn&apos;t matter if your device is now active or not. All you need is Modem Number.</p>
        <div className="assetButtons">
          <Link className="btn btn-primary btn-lg" to={URLS.ASSETS} role="button" data-cy="assetButtonAdd">Add your first Asset</Link>
          <a className="btn btn-outline-secondary btn-lg" onClick={this.handleClose} role="button" data-cy="assetButtonLater">Later</a>
        </div>
      </div>
    )
  }
}
