import React, { Component } from 'react'

export default class Notification extends Component {
  constructor (props) {
    super(props)

    this.state = {
      animation: ''
    }

    this.timer = setTimeout(() => {
      this.onClick()
    }, 2000)
  }

  onClick = (event) => {
    if (event) {
      event.preventDefault()
    }

    this.setState({ animation: 'animated fadeOut' })
  }

  onAnimationEnd = () => {
    this.setState({ animation: 'hidden' })
    this.props.onHide(this.props.message.id)
  }

  componentWillUnmount () {
    clearTimeout(this.timer)
  }

  render () {
    const { message: { type, text } } = this.props
    const className = 'Notification alert alert-' + type + ' ' + this.state.animation
    return (
      <div onAnimationEnd={this.onAnimationEnd} onClick={this.onClick} className={className}>
        {text}
      </div>
    )
  }
}
