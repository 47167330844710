import React, { Fragment } from 'react'
import ContentWrapper from '../ContentWrapper'
import SeoContent from '../SeoContent'
import URLS from '../../config/urls'
import { Link } from 'react-router-dom'
import logo from './logo.png'
import logPicOne from './logPicOne.jpeg'
import logPicTwo from './logPicTwo.jpeg'
import logBigPic from './logBigPic.jpeg'
import './index.less'

const APP_URL = process.env.REACT_APP_BASE_URL
const seo = {
  title: 'GPS Fleet Tracking | Truck & Trailer Tracking | EcoTrack',
  description:
    'Stick & Track, No Installation Truck & Trailer Tracking - 10 Year Life with rates as low as $3 per month. With EcoTrack you can easily track your trucks and trailers as they zip around the country, helping you increase efficiency, track dormancy and ensure equipment is accounted for.',
  url: `${APP_URL}${URLS.LOGISTICS}/`,
  image: APP_URL + logo
}

const Logistics = () => (
  <Fragment>
    <ContentWrapper>
      <SeoContent {...seo} />
      <div className="bg">
        <h1 className="display-3 text-light text-center h1-landing">Logistics</h1>
      </div>
      <hr className="line-center" />
      <h2 className="h2-landing text-secondary">Truck & Trailer Tracking</h2>
      <p className="p-landing text-center mt-4">
      You maintain a firm control of logistics for your transportation company.
      You do your best to make sure you know where every vehicle, trailer, and load is at all times.
      Who could blame you? You’re exactly the reason we launched EcoTrack’s GPS Fleet Tracking solution.<br/>
      An easy, low cost way to keep up with your fleet.
      </p>
      <div className="logistics-one">
        <div className="pic-one">
          <img className="img-fluid rounded" src={logPicOne} />
        </div>

        <div className="log-truck">
          <h3 className="h3-landing px-5 text-dark">Wherever your truck, trailer or load goes, we go too.</h3>
          <p className="p-landing">
          Through EcoTrack, you’re able to not only use it’s low cost
          fleet tracking satellite technology to monitor the locations of your equipment, but you’re also to use this
          information to calculate things like dormancy rate (how long a vehicle or its load has sat dormant and where)
          or utilization rate (how often the asset is in the field and working) enabling you to track fundamentally
          important fleet data points.</p>
        </div>
      </div>
      <h3 className="h3-landing text-dark"> Simple Install - Low Cost - Top Notch System</h3>
      <p className="p-landing">
          By using the EcoTrack dashboard, you’re also able to track tasks & reminders, retrieve vehicle location
          from anywhere with ease, group categories of equipment, and track utilization and dormancy all powered by
          Ecotrack’s simple GPS Fleet Management System. We want to make sure that any under-utilized vehicles are repurposed to increase
          your revenue instead of gathering dust.
          While teamwork is paramount in a logistics focused company, communication is the thread that holds teams and companies together.
          By using the EcoTrack line of logistics tracking solutions, you have the ability to share data related to specific assets with select
          3rd parties at your fingertips. Share your data with the teams and subcontractors that need it most by grouping and tagging your inventory
          and equipment based on who needs visibility.
      </p>

      <div className="middle-page-btn">
        <Link to={URLS.SIGN_UP} className="btn btn-primary btn-lg p-3">Get started with EcoTrack</Link>
      </div>
      <div className="logistics-two">
        <div className="log-safety">
          <h3 className="h3-landing text-dark">Safety</h3>
          <p className="p-landing">
          One important part of using an EcoTrack GPS Fleet Tracker is providing one more piece of safety equipment for our customers
          and their employees. Incase of emergency, your driver and their loads can be easily located with an increased ability to provide all necessary
          assistance quicker and more efficiently than ever. Even if a vehicle or trailer is stolen, our Geofencing features will alert all necessary parties
          immediately, allowing for a quick response. Customers can also use EcoTrack to create and modify routes that not only move fleets and cargo efficiently,
          but provide the safest route to any destination as well.
          </p>
        </div>
        <div className="pic-two">
          <img className="img-fluid rounded" src={logPicTwo} alt=""/>
        </div>
      </div>
      <h3 className="h3-landing text-dark">Stick and Track - No headache simple setup</h3>
      <p className="p-landing">
          By using two cell and two way batteries in our hardware packs, customers receive an EcoTracker
          with a battery that will provide up to 10 years of service in almost any condition you could put it through.
          We believe adaptability is one of the cornerstones of a successful business and that’s why we provide our customers
          with a product that can operate independent of a vehicle’s power system. This way the EcoTrack will be providing data
          no matter what happens to the truck or it’s electrical system. Whether you track , one small group of equipment or your entire fleet,
          we have you and your logistics company covered.
      </p>
      <div>
        <img className="big-picture rounded" src={logBigPic} alt=""/>
      </div>
      <div className="logistics-three">
        <div className="log-tracking">
          <h3 className="h3-landing text-dark">Tracking solution</h3>
          <p className="p-landing">
          Our goal with EcoTrack is to decrease dormancy rates and increase utilization for every one of our customers across all their asset classes.
          That’s why our product enables companies to track all types of equipment including trailer gps tracking, commercial vehicle tracking,
          general vehicle GPS tracking, and equipment tracking.. At base level, EcoTrack is a tool that was designed to help drivers and their companies,
          so browse our newest technology and find a GPS tracking solution that works best for you.
          </p>
        </div>
        <div className="price-button-div">
          <div className="bg-picture-price">
            <h2 className="h2-button">Get started with EcoTrack</h2>
            <Link to={URLS.PRICING} className="btn btn-light price-button">Check our prices</Link>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </Fragment>
)

export default Logistics
