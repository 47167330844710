import React, { Component } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import AssetTracking from './AssetTracking'
import assetQuery from '../AssetsSidebarContent/asset.graphql'
import activitiesQuery from './activities.graphql'
import { getActivities } from './fakeData'
import { FILTER_TYPES, US_DATE_FORMAT } from '../../config/constants'
import { formatDate } from '../../lib'

class AssetTrackingWithQuery extends Component {
  static getDateFromFilterToNow = (filterType) => {
    let from = null
    if (filterType === FILTER_TYPES.DAYS7) from = moment().subtract(7, 'days')
    if (filterType === FILTER_TYPES.DAYS14) from = moment().subtract(14, 'days')
    if (filterType === FILTER_TYPES.DAYS30) from = moment().subtract(30, 'days')

    return {
      from,
      to: moment()
    }
  }

  state = {
    activeFilter: FILTER_TYPES.DAYS30,
    filters: AssetTrackingWithQuery.getDateFromFilterToNow(FILTER_TYPES.DAYS30)
  }

  onSetFilters = filters => this.setState({ filters })

  onSetActiveFilter = activeFilter => this.setState({ activeFilter })

  onChange = (filters, activeFilter) => {
    if (filters) {
      this.onSetFilters(filters)
    } else {
      const quickFilter = this.getDateFilters(activeFilter)
      this.onSetActiveFilter(quickFilter)
    }
    this.onSetActiveFilter(activeFilter)
  }

  getDateFilters = () => {
    const { filters: { from, to } } = this.state
    return {
      from: formatDate(from, US_DATE_FORMAT),
      to: formatDate(to, US_DATE_FORMAT)
    }
  }

  render () {
    const { computedMatch: { params }, location: { hash } } = this.props
    const { activeFilter, filters } = this.state
    return (
      <Query query={assetQuery} variables={{ id: params.assetId }}>
        {({ data, loading }) => {
          return (
            !loading && (
              <Query query={activitiesQuery} variables={{ asset: params.assetId, ...this.getDateFilters() }}>
                {({ data: activitiesData = {}, loading: activitiesLoading }) =>
                  !activitiesLoading.loading && (
                    <AssetTracking
                      {...data}
                      {...activitiesData}
                      activities={hash ? getActivities(hash) : activitiesData.activities}
                      onChange={this.onChange}
                      activeFilter={activeFilter}
                      filters={filters}
                    />
                  )
                }
              </Query>
            )
          )
        }}
      </Query>
    )
  }
}

export default withRouter(AssetTrackingWithQuery)
