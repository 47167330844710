import React, { Component, Fragment } from 'react'
import { Map as GoogleMap, GoogleApiWrapper, InfoWindow } from 'google-maps-react'
import { withApollo } from 'react-apollo'
import sortBy from 'lodash/sortBy'
import last from 'lodash/last'
import flowRight from 'lodash/flowRight'
import AssetTrackingSideBar from './AssetTrackingSideBar'
import MarkerCluster from '../MarkerCluster'
import isEqual from 'lodash/isEqual'
import { formatDate } from '../../lib'
import './index.less'

class AssetTracking extends Component {
  zoom = 13
  map = null
  state = {
    markers: [],
    activeMarker: null,
    selectedActivityId: null,
    showingInfoWindow: false
  }

  async componentDidUpdate (prevProps, prevState, snapshot) {
    if (!isEqual(this.props.activities, prevProps.activities)) {
      await this.onReady(null, this.map)
    }
  }

  onClose = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  }

  onActivityClick = activityId => {
    const { activities } = this.props
    const activity = activities.find(({ id }) => id === activityId) || {}
    this.setState({ selectedActivityId: activityId, activeMarker: null, showingInfoWindow: false })
    this.map.setCenter(activity.geolocation)
    this.map.setZoom(12)
  }

  onMarkerClick = ({ marker }) => this.setState({ activeMarker: marker, showingInfoWindow: true })

  onReady = async (_, map) => {
    this.map = map
    const markers = []
    const bounds = new this.props.google.maps.LatLngBounds()
    const { google, activities, asset } = this.props
    if (activities && activities.length) {
      const latestActivity = this.getLatestActivity(activities)
      activities.forEach(({ id, date, geolocation: { lat, lng } }) => {
        bounds.extend({ lat: Number(lat), lng: Number(lng) })
        markers.push(
          new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            icon: AssetTracking.markerCluster,
            date: formatDate(date),
            isLatest: id === latestActivity.id,
            map
          })
        )
      })
    } else {
      const {
        geolocation: { lat, lng },
        created
      } = asset
      bounds.extend({ lat: Number(lat), lng: Number(lng) })
      markers.push(
        new google.maps.Marker({
          position: new google.maps.LatLng(lat, lng),
          icon: AssetTracking.markerCluster,
          isLatest: true,
          date: formatDate(created),
          map
        })
      )
    }
    this.setState({ markers })
    if (bounds || bounds.length) {
      this.map.fitBounds(bounds)
    }
  }

  getLatestActivity = activities => last(sortBy(activities, 'date'))

  getInitialCenter = () => {
    const { activities, asset } = this.props
    if (activities && activities.length) {
      const latestActivity = this.getLatestActivity(activities)
      return latestActivity.geolocation
    }

    return asset.geolocation
  }

  getActivities = () => {
    const { activities } = this.props
    return sortBy(activities, 'date')
  }

  render () {
    const { asset, onChange, activeFilter, filters } = this.props
    const { activeMarker, showingInfoWindow, selectedActivityId } = this.state
    return (
      <div>
        <div className="map-wrapper">
          <GoogleMap
            onReady={this.onReady}
            google={this.props.google}
            zoom={this.zoom}
            initialCenter={this.getInitialCenter()}
          >
            <MarkerCluster
              map={this.map}
              markers={this.state.markers}
              google={this.props.google}
              click={this.onMarkerClick}
              icon={AssetTracking.markerCluster}
            />
            <InfoWindow
              key="info-window"
              marker={activeMarker}
              visible={showingInfoWindow}
              onClose={this.onClose}
            >
              <Fragment>
                <h6>Activity</h6>
                {activeMarker && <h6 className="text-primary strong">{activeMarker.date}</h6>}
              </Fragment>
            </InfoWindow>
          </GoogleMap>
        </div>
        <AssetTrackingSideBar
          {...asset}
          activities={this.getActivities()}
          onActivityClick={this.onActivityClick}
          onChange={onChange}
          activeFilter={activeFilter}
          filters={filters}
          selectedActivityId={selectedActivityId}
        />
      </div>
    )
  }
}

AssetTracking.markerCluster =
  'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'

export default flowRight(
  GoogleApiWrapper({
    apiKey: 'AIzaSyBw36MVCdLGxMC1Q7WKFDK_XqWCbqF7NRs'
  }),
  withApollo
)(AssetTracking)
