import React, { Fragment } from 'react'
import Header from './Header'

const PublicRoute = ({
  component: Component,
  ...rest
}) => (
  <Fragment>
    <Header />
    <Component {...rest} />
  </Fragment>
)

export default PublicRoute
