import React from 'react'
import { Input } from '../index'
import {
  TITLE_CANCEL,
  TITLE_DELETE,
  TITLE_SAVE_CHANGES,
  INLINE_OPERATOR_MODES
} from '../../config/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faSpinnerThird,
  faTimes
} from '@fortawesome/pro-regular-svg-icons'
import './index.less'

const { EDITABLE, DELETABLE } = INLINE_OPERATOR_MODES

export const EditableBlock = ({
  value,
  onEdit,
  onChange,
  busy,
  onToggleMode,
  saveTitle = TITLE_SAVE_CHANGES,
  cancelTitle = TITLE_CANCEL,
  dataTest
}) => (
  <div className="inline-editable-block" data-cy={dataTest}>
    <Input
      label={null}
      value={value}
      onChange={onChange}
      loading={busy}
      disabled={busy}
    />
    <span className="primary-icon" onClick={onEdit} title={saveTitle}>
      <FontAwesomeIcon icon={faCheck} />
    </span>
    <span
      className="primary-icon"
      onClick={e => onToggleMode(e, EDITABLE)}
      title={cancelTitle}
    >
      <FontAwesomeIcon icon={faTimes} />
    </span>
  </div>
)

export const DeletableBlock = ({
  busy,
  onDelete,
  onToggleMode,
  deleteTitle = TITLE_DELETE,
  cancelTitle = TITLE_CANCEL,
  deleteQuestion,
  dataTest
}) => (
  <div className="inline-deletable-block" data-cy={dataTest}>
    <strong>{deleteQuestion}</strong>
    <span className="primary-icon" onClick={onDelete} title={deleteTitle}>
      <FontAwesomeIcon icon={busy ? faSpinnerThird : faCheck} spin={busy} />
    </span>
    <span
      className="primary-icon"
      onClick={e => onToggleMode(e, DELETABLE)}
      title={cancelTitle}
    >
      <FontAwesomeIcon icon={faTimes} />
    </span>
  </div>
)
