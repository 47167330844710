import faker from 'faker'
import moment from 'moment'

export const getActivities = hash => {
  return new Array(Number(hash.split('#')[1])).fill(0).map(() => ({
    id: faker.random.uuid(),
    date: moment(faker.date.past()).valueOf(),
    geolocation: {
      lat: Number(faker.address.latitude()),
      lng: Number(faker.address.longitude())
    }
  }))
}
