import React, { Component } from 'react'
import union from 'lodash/union'
import Notification from '../Notification'
import './index.less'

let notify

class Notifications extends Component {
  constructor (props) {
    super(props)
    this.onHide = this.onHide.bind(this)

    this.state = {
      lastMessageId: 0,
      messages: []
    }
    notify = this.notify
  }

  notify = (text, type = 'success') => {
    this.setState({ messages: union(this.state.messages, [{ id: this.state.lastMessageId, type: type, text }]) })
    this.setState({ lastMessageId: this.state.lastMessageId + 1 })
  }

  onHide (messageId) {
    this.setState({ messages: this.state.messages.filter(message => message.id !== messageId) })
  }

  render () {
    if (!this.state.messages.length) {
      return null
    }

    let notifications = []
    this.state.messages.forEach(message => {
      notifications.push(
        <Notification key={message.id} onHide={this.onHide} message={message} />
      )
    })

    return (
      <div className="Notifications">
        {notifications}
      </div>
    )
  }
}

export { notify }
export default Notifications
