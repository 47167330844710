import React, { Component, Fragment } from 'react'
import { notify } from '../Notifications'
import { NoData, InlineInputAdd } from '../index'
import TagType from '../TagType'
import './index.less'

class TagTypes extends Component {
  state = {
    name: '',
    busy: false,
    open: false
  }

  inputRef = React.createRef()

  onAddTagType = async e => {
    e.preventDefault()
    const { busy } = this.state
    if (!busy) {
      this.setState({ busy: true })
      const { createMutation } = this.props
      const { name } = this.state
      if (!name) return

      const variables = { name }
      const {
        data: {
          createTagType: {
            result: { success, reason }
          }
        }
      } = await createMutation({ variables })
      if (success) {
        return this.setState({ name: '', busy: false })
      } else {
        this.setState({ busy: false })
        notify(reason.message, 'danger')
      }
    }
  }

  onRemoveTagType = async (tagTypeId, name) => {
    const { removeMutation } = this.props
    const data = await removeMutation({
      variables: { id: tagTypeId }
    })
    const {
      data: {
        removeTagType: {
          result: { success, reason }
        }
      }
    } = data
    if (success) {
      notify(`Tag Category ${name} is now removed!`, 'success')
    } else {
      notify(reason.message, 'danger')
    }
  }

  onChange = name => this.setState({ name })

  onFocusInput = () => {
    if (this.inputRef) {
      this.inputRef.focus()
    }
  }

  renderNoDataContentComponent = () => {
    const onOverrideClick = e => {
      e.preventDefault()
      this.onFocusInput()
    }
    return (
      <Fragment>
        <div className="title">Tag Categories list is empty!</div>
        <div className="secondary-title">
          <a href="#" onClick={onOverrideClick}>Add your first category.</a>
        </div>
      </Fragment>
    )
  }

  render () {
    const { tagTypes } = this.props
    const { name, busy } = this.state
    return (
      <Fragment>

        <InlineInputAdd
          title="Tag Categories"
          inputTitle="Add tag category:"
          onChange={this.onChange}
          onClick={this.onAddTagType}
          value={name}
          busy={busy}
          inputRef={ref => {
            this.inputRef = ref
          }}
          dataTest="tagTypesAdd"
        />

        <div className="tag-types">
          {tagTypes.length > 0 ? (
            tagTypes.map(({ id, name }) => (
              <TagType
                key={id}
                id={id}
                name={name}
                onRemoveTagType={() => this.onRemoveTagType(id, name)}
              />
            ))
          ) : (
            <NoData
              contentComponent={this.renderNoDataContentComponent}
              fullWidth
            />
          )}
        </div>
      </Fragment>
    )
  }
}

export default TagTypes
