import React from 'react'
import { Query, withApollo } from 'react-apollo'
import isFunction from 'lodash/isFunction'
import flatten from 'lodash/flatten'
import { TAG_TYPES_AND_TAGS_POOL_INTERVAL } from '../../config/constants'
import tagTypesWithTagsQuery from './tagTypesWithTags.graphql'
import { client } from '../../index'

const TagTypesWithTags = ({ children }) => (
  <Query query={tagTypesWithTagsQuery} client={client} pollInterval={TAG_TYPES_AND_TAGS_POOL_INTERVAL}>
    {({ loading, data: { tagTypes = [] } = {} }) => {
      const filteredTagTypes = tagTypes.filter(tagType => tagType.tags.length)
      const allTagsIds = flatten(tagTypes.map(({ tags }) => tags.map(({ id }) => id)))
      return children && isFunction(children) ? children({ loading, tagTypes: filteredTagTypes, allTagsIds }) : null
    }}
  </Query>
)

export default withApollo(TagTypesWithTags)
