import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { TITLE_CLOSE } from '../../config/constants'
import './index.less'

class Dialog extends Component {
  state = {
    open: false
  }

  componentDidMount () {
    document.addEventListener('keydown', this.onEscapeKeyClose, false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.onEscapeKeyClose, false)
  }

  onEscapeKeyClose = ({ keyCode }) => {
    if (keyCode === 27) {
      this.setState({ open: false })
    }
  }

  onToggleDialog = () => this.setState({ open: !this.state.open })

  renderDialog = () => {
    const { contentComponent: ContentComponent, title } = this.props
    return (
      <div className="Dialog" onClick={this.onToggleDialog}>
        <div className="dialog-frame" onClick={e => e.stopPropagation()}>
          <div className="dialog-header" data-cy="dialogHeader">
            <h5>{title}</h5>
            <div className="close-icon" onClick={this.onToggleDialog} title={TITLE_CLOSE}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
          <ContentComponent onToggleDialog={this.onToggleDialog} />
        </div>
      </div>
    )
  }

  render () {
    const { open } = this.state
    return (
      <Fragment>
        {this.props.children({ onToggleDialog: this.onToggleDialog })}
        {open && ReactDOM.createPortal(this.renderDialog(), document.body)}
      </Fragment>
    )
  }
}

Dialog.propTypes = {
  contentComponent: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired
}

export default Dialog
