import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment'
import range from 'lodash/range'
import './index.less'

export default class CalendarRange extends Component {
  state = {
    currentMonth: moment().startOf('month'),
    from: false,
    to: false,
    hoverTo: false
  }

  onDayClick = e => {
    const day = moment(e.currentTarget.dataset.day)
    e.preventDefault()
    if (!this.state.from) {
      return this.setState({ from: day })
    }

    if (this.state.from && !this.state.to) {
      if (day.isBefore(this.state.from)) {
        this.setState({ hoverTo: false, to: this.state.from, from: day })
        return this.props.onChange({ from: day, to: this.state.from })
      } else {
        this.setState({ hoverTo: false, to: day })
        return this.props.onChange({ to: day, from: this.state.from })
      }
    }

    if (this.state.from && this.state.to) {
      return this.setState({ from: day, to: false })
    }
  }

  onMonthClick = () => this.setState({ currentMonth: moment().startOf('month') })

  onHoverStart = e => {
    if (this.state.from && !this.state.to) {
      const day = moment(e.currentTarget.dataset.day)
      return this.setState({ hoverTo: day })
    }
  }

  onHoverEnd = () => {
    this.setState({ hoverTo: false })
  }

  render () {
    const { state: { currentMonth } } = this
    const firstDay = moment(currentMonth).startOf('month').startOf('week')
    const numberOfWeeks = Math.ceil(moment(currentMonth).endOf('month').endOf('week').diff(
      firstDay, 'days'
    ) / 7)

    return (
      <div className="CalendarRange">
        <div className="calendar-header">
          <a onClick={this.onPrevMonth} href="#month-back" className="btn btn-link calendar-arrow">
            <FontAwesomeIcon icon={faChevronLeft}/>
          </a>
          <span className="current-month" onClick={this.onMonthClick}>{currentMonth.format('MMMM YYYY')}</span>
          <a onClick={this.onNextMonth} href="#month-forward" className="btn btn-link calendar-arrow">
            <FontAwesomeIcon icon={faChevronRight}/>
          </a>
        </div>
        <table>
          <thead>
            <tr>
              {range(7).map(day => <th key={day}>{currentMonth.startOf('week').add(day, 'days').format('ddd')}</th>)}
            </tr>
          </thead>
          <tbody onMouseLeave={this.onHoverEnd}>
            { range(numberOfWeeks).map(week => <tr key={week}>
              { range(7).map(dayOfWeek => {
                const day = moment(firstDay).add(week, 'weeks').add(dayOfWeek, 'days')
                return (
                  <td onMouseEnter={this.onHoverStart} data-day={day.format('YYYY-MM-DD')} onClick={this.onDayClick} className={this.getDayClassName(day)} key={dayOfWeek}>{day.format('D')}</td>
                )
              })}
            </tr>)}
          </tbody>
        </table>
      </div>
    )
  }
  getDayClassName = (day) => {
    const classes = []
    if (moment().isSame(day, 'day')) {
      classes.push('today')
    }
    if (!this.state.currentMonth.isSame(day, 'month')) {
      classes.push('other-month')
    }

    if (this.state.from && this.state.from.isSame(day, 'day')) {
      classes.push('range')
    }

    if (this.state.from && this.state.to &&
        day.isBetween(this.state.from, moment(this.state.to).add(1, 'day'), 'day')) {
      classes.push('range')
    }

    if (
      this.state.from && this.state.hoverTo &&
      (
        day.isBetween(this.state.from, moment(this.state.hoverTo).add(1, 'day'), 'day') ||
        day.isBetween(moment(this.state.hoverTo).add(-1, 'day'), this.state.from, 'day')
      )
    ) {
      classes.push('in-range')
    }

    return classes.join(' ')
  }

  onNextMonth = e => {
    if (e) {
      e.preventDefault()
    }
    this.setState({ currentMonth: moment(this.state.currentMonth).startOf('month').add(1, 'month') })
  }

  onPrevMonth = e => {
    if (e) {
      e.preventDefault()
    }
    this.setState({ currentMonth: moment(this.state.currentMonth).startOf('month').add(-1, 'month') })
  }
}
