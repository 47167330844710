import Input from '../Input'
import React, { Fragment } from 'react'

const ResetForm = ({ errors, onSubmit, onChange, password, onKeyPress }) => (
  <Fragment>
    <form className="Form" onSubmit={onSubmit}>
      <Input type="password" error={errors.password} onKeyPress={onKeyPress} value={password} onChange={onChange('password')} label="New Password" />
      <p className="Form__Buttons">
        <button className="mt-2 btn btn-primary">Change My Password</button>
      </p>
    </form>
  </Fragment>
)

export default ResetForm
