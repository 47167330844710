import React, { Component } from 'react'
import ReactSideBar from 'react-sidebar'
import isArray from 'lodash/isArray'
import { withRouter } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import classNames from 'classnames'
import Search from '../../components/Search'
import { ContentWrapper, SideBarHeader, Button, NoData, Loader } from '../../components/index'
import AssetsSidebarContent from '../../components/AssetsSidebarContent'
import { faList } from '@fortawesome/pro-regular-svg-icons'
import AssetsTable from '../../components/AssetsTable'
import './index.less'

class AssetsView extends Component {
  state = {
    busy: false,
    name: '',
    selected: [],
    open: false,
    activeAssetId: null
  }

  componentDidMount () {
    document.addEventListener('keydown', this.onHandleEscKey)
  }

  onHandleEscKey = ({ which }) => {
    if (which !== 27) return null
    this.setState({ open: false })
  }

  onChange = name => this.setState({ name })

  onSelectAsset = id => {
    const { selected } = this.state
    this.setState({
      selected: isArray(id)
        ? []
        : selected.includes(id)
          ? selected.filter(selectedId => selectedId !== id)
          : [...selected, id]
    })
  }

  onSetActiveAssetId = activeAssetId => this.setState({ activeAssetId })

  onRedirectToTracking = assetId => this.props.history.push('/asset-tracking/' + assetId)

  renderAssetsList = () => {
    const sortedByNameAssets = sortBy(this.props.assets, 'name')
    const assets = sortedByNameAssets.map(({ tags, device, geolocation, ...asset }) => ({
      ...asset,
      lastUpdated: asset.modified,
      geolocation: get(geolocation, 'lat') && `${get(geolocation, 'lat')}, ${get(geolocation, 'lng')}`,
      tags: tags || [],
      deviceId: get(device, 'modemNumber'),
      status: (get(device, 'status') || '').toLowerCase(),
      onOpenSideBar: () => {
        this.onOpenSidebar()
        this.onSetActiveAssetId(asset.id)
      },
      onNameClick: () => this.onRedirectToTracking(asset.id)
    }))
    return (
      <ul className="list-group p-2" data-cy="assetsListGroup">
        <AssetsTable assets={assets} />
      </ul>
    )
  }

  onOpenSidebar = () => this.setState({ open: !this.state.open })

  onCloseSideBar = () => this.setState({ open: false })

  render () {
    const { activeAssetId, open } = this.state
    const { assets, loading } = this.props
    const activeAsset = assets.find(({ id }) => id === activeAssetId) || {}
    return (
      <ContentWrapper>
        <div className="p-2">
          <Search />
        </div>
        <div className="assets-header">
          <h3>Assets</h3>
          <ReactSideBar
            rootClassName="side-bar-root"
            sidebarClassName={classNames('side-bar-wrapper', { open: !!open })}
            overlayClassName="side-bar-overlay-wrapper"
            open={open}
            onSetOpen={this.onOpenSidebar}
            styles={{ sidebar: { background: 'white' } }}
            pullRight
            sidebar={
              <span>
                <SideBarHeader
                  onClose={this.onCloseSideBar}
                  title={activeAsset.name || 'Add new asset'}
                />
                <AssetsSidebarContent
                  isOpened={open}
                  onClose={this.onCloseSideBar}
                  onClearSideBar={() => this.onSetActiveAssetId(null)}
                  id={activeAssetId}
                />
              </span>
            }
          >
            <Button label="Add Asset" onClick={() => {
              this.onOpenSidebar()
              this.onSetActiveAssetId(null)
            }} />
          </ReactSideBar>
        </div>
        {assets.length > 0 && !loading ? (
          this.renderAssetsList()
        ) : assets.length === 0 && !loading ? (
          <NoData
            title="No results found!"
            secondaryTitle="Add your first asset."
            icon={faList}
            fullWidth
          />
        ) : (
          <Loader />
        )}
      </ContentWrapper>
    )
  }
}

export default withRouter(AssetsView)
