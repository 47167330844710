import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldCheck, faMapMarkedAlt, faCogs, faMoneyCheckAlt, faTruckContainer, faPiggyBank } from '@fortawesome/pro-regular-svg-icons'
import URLS from '../../config/urls'
import { Link } from 'react-router-dom'
import hardwareFeat from './hardwareFeat.jpg'
import softwareFeat from './softwareFeat.jpg'

import './index.less'

const HomeView = () => (
  <Fragment>
    <div className="jumbotron homepage-pic">
      <div className="container">
        <h1 className="display-3 text-light">Track All Your Assets</h1>
        <p className="text-light">We have it all: up to five years battery life, low monthly fee and world-wide global satellite coverage.</p>
        <Link to={URLS.SIGN_UP} className="btn btn-primary btn-lg">Start Tracking &raquo;</Link>
      </div>
    </div>

    <div className="container marketing">
      <h1 className="display-4 text-center mb-5" id="solutions">Solutions</h1>
      <div className="landing-pages">
        <div className="land-log text-center">
          <FontAwesomeIcon icon={faMapMarkedAlt} size="4x" className="d-block mx-auto text-secondary" />
          <h2 className="h2-home">Logistics</h2>
          <h3 className="h3-home">Truck & Trailer GPS Tracking</h3>
          <p className="text-justify">With EcoTrack you can easily track
          your trucks and trailers as they zip around the country,
          helping you increase efficiency, track dormancy and ensure equipment is accounted for.</p>
          <Link className="btn btn-outline-primary btn-sm" to={URLS.LOGISTICS}> READ MORE </Link>
        </div>
        <div className="land-const text-center">
          <FontAwesomeIcon icon={faCogs} size="4x" className="d-block mx-auto text-secondary" />
          <h2 className="h2-home">Construction</h2>
          <h3 className="h3-home">Heavy Equipment GPS Tracking</h3>
          <p className="text-justify">With EcoTrack, no matter how far apart your job sites are,
          you’ll always know where your trucks and equipment are. You track your progress; we’ll track your equipment.</p>
          <Link className="btn btn-outline-primary btn-sm" to={URLS.CONSTRUCTION}> READ MORE </Link>
        </div>
        <div className="land-ship text-center">
          <FontAwesomeIcon icon={faTruckContainer} size="4x" className="d-block mx-auto text-secondary" />
          <h2 className="h2-home">Shipping Container</h2>
          <h3 className="h3-home">Shipping Container GPS Tracking</h3>
          <p className="text-justify">Shipping Containers can move from ships to trains to trucks. EcoTrack makes sure they don’t skip any steps and arrive where they need to be,
          when they need to be there. We’ll keep an eye on all your intermodals.</p>
          <Link className="btn btn-outline-primary btn-sm" to={URLS.SHIPPING_CONTAINER}> READ MORE </Link>
        </div>
        <div className="land-leas text-center">
          <FontAwesomeIcon icon={faMoneyCheckAlt} size="4x" className="d-block mx-auto text-secondary" />
          <h2 className="h2-home">Leasing</h2>
          <h3 className="h3-home">GPS Asset Track Tracking</h3>
          <p className="text-justify">Whether you lease cars, trucks, trailers, or construction machines, use EcoTrack to manage your fleet
          by understanding equipment dormancy and utilization, monitor on-site assets, and ensure a constant GPS location of fleet assets.</p>
          <Link className="btn btn-outline-primary btn-sm" to={URLS.LEASING}> READ MORE </Link>
        </div>
        <div className="land-fin text-center">
          <FontAwesomeIcon icon={faPiggyBank} size="4x" className="d-block mx-auto text-secondary" />
          <h2 className="h2-home">Financing</h2>
          <h3 className="h3-home">Bank Owned GPS Asset Tracking</h3>
          <p className="text-justify">Manage lender’s risk while gaining insight into borrower performance.
          As a financier or lender, tracking financed assets with EcoTrack
          can help reduce risk by increasing asset visibility while providing valuable customer data, informing future investments.</p>
          <Link className="btn btn-outline-primary btn-sm" to={URLS.FINANCING}> READ MORE </Link>
        </div>
        <div className="land-ins text-center">
          <FontAwesomeIcon icon={faShieldCheck} size="4x" className="d-block mx-auto text-secondary" />
          <h2 className="h2-home">Insurance</h2>
          <h3 className="h3-home">Insurance Based GPS Asset Tracking Solution</h3>
          <p className="text-justify">Manage insurance risk while gaining insight into fleet performance and location. As an insurance provider,
          it’s critical to gain insights into things like asset geo-location, to help gather insights into risk exposures created by equipment clustering.</p>
          <Link className="btn btn-outline-primary btn-sm" to={URLS.INSURANCE}> READ MORE </Link>
        </div>
      </div>
      <hr className="featurette-divider mt-5"/>
    </div>

    <h1 className="display-4 text-center mb-5" id="product-overview">Product Overview</h1>
    <div className="Features container">
      <div className="featurette1">
        <h2 className="featurette-heading"><Link className="text-secondary" to={URLS.HARDWARE_FEATURES}>Hardware <span className="text-primary">Features &raquo;</span></Link></h2>
        <div className="lead">
          <ul>
            <li>Long Battery Life</li>
            <li>Build Tough (Water, Heat & Cold Resist)</li>
            <li>Easy Install</li>
            <li>Global Coverage</li>
            <li>Low Price</li>
          </ul>
        </div>
      </div>
      <div className="picture1">
        <img className="img-fluid rounded" src={hardwareFeat} />
      </div>

      <div className="featurette2">
        <h2 className="featurette-heading"><Link className="text-secondary" to={URLS.SOFTWARE_FEATURES}>Software <span className="text-primary">Features &raquo;</span></Link></h2>
        <div className="lead">
          <ul>
            <li>Asset Tracking Dashboard</li>
            <li>Maps - Asset Pinning</li>
            <li>User Management System</li>
            <li>Category Tags & Grouping</li>
            <li>Reminders & Notes</li>
            <li>Outside User Sharing</li>
          </ul>
        </div>
      </div>
      <div className="picture2">
        <img className="img-fluid rounded" src={softwareFeat} />
      </div>

      <hr className="featurette-divider"/>
    </div>
  </Fragment>
)

export default HomeView
