import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import truncate from 'lodash/truncate'

const SeoContent = ({ title, description, type, url, image, locale }) => {
  const truncatedDescription = truncate(description, { length: 164 })
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}

      {truncatedDescription && <meta name="description" content={truncatedDescription} />}
      {truncatedDescription && <meta name="og:description" content={truncatedDescription} />}

      {url && <meta property="og:url" content={url} />}
      {url && <link rel="canonical" href={url} />}

      {type && <meta property="og:type" content={type} />}

      {image && <meta property="og:image" content={image} />}

      {locale && <meta property="og:locale" content={locale} />}
    </Helmet>
  )
}

SeoContent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.string,
  type: PropTypes.string,
  locale: PropTypes.string
}

SeoContent.defaultProps = {
  locale: 'en_US',
  type: 'business.business'
}

export default SeoContent
