import React, { Fragment } from 'react'
import logo from './logo.png'
import { Link, withRouter } from 'react-router-dom'
import URLS from '../../config/urls'
import ScrollToTop from '../ScrollToTop'

const Footer = ({ location: { pathname } }) => !pathname.match('asset-tracking') && (
  <Fragment>
    <footer className="default-wrapper navbar pt-4 my-md-5 pt-md-5 border-top">
      <div className="row">
        <ScrollToTop />
        <Link className="col-12 col-md" to={URLS.HOME} >
          <img className="mb-2" src={logo} alt="" height="64" />
          <small className="d-block mb-3 text-muted">&copy; 2017-2019</small>
        </Link>
        <div className="col-6 col-md">
          <h5>Resources</h5>
          <ul className="list-unstyled text-small">
            <li><a className="text-muted" href="#">Buy Hardware</a></li>
            <li><Link to={URLS.FAQ}>FAQ</Link></li>
            <li><Link to={URLS.SUPPORT}>Support</Link></li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5>Company</h5>
          <ul className="list-unstyled text-small">
            <li><a className="text-muted" href="#">Why EcoTrack?</a></li>
            <li><Link to={URLS.ABOUT_US}>About Us</Link></li>
            <li><a className="text-muted" href="#">Team</a></li>
            <li><Link to={URLS.CONTACT}>Contact Us</Link></li>
            <li><Link to={URLS.PRIVACY}>Privacy Policy</Link></li>
            <li><Link to={URLS.TERMS}>Terms and Conditions</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  </Fragment>
)

export default withRouter(Footer)
