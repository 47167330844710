import React, { Fragment } from 'react'
import Header from '../Header'
import './index.less'

const NotFound = () => (
  <Fragment>
    <Header />
    <div className="NotFound">
      <h2>404</h2>
      <h6>Page Not Found</h6>
      <small>
        Sorry, but the page you are looking for has note been found. Try checking the URL for error,
        then hit the refresh button on your browser.
      </small>
    </div>
  </Fragment>
)

export default NotFound
