import React, { Component } from 'react'

// Local packages
import SearchTagTypes from './SearchTagTypes'
import SearchContext from '../../lib/searchContext'
import './index.less'

class Search extends Component {
  render () {
    return (
      <SearchContext.Consumer>
        {({ onSetTags, tags, query, onSetQuery }) => (
          <div className="search input-group mb-3">
            <SearchTagTypes onSetTags={onSetTags} tags={tags} />
            <input
              style={{ width: '100%' }}
              type="text"
              value={query}
              className="form-control"
              id="formGroupExampleInput2"
              placeholder="Search"
              onChange={({ target: { value } }) => onSetQuery(value)}
            />
          </div>
        )}
      </SearchContext.Consumer>
    )
  }
}

export default Search
