import React, { Fragment } from 'react'
import Map from '../components/Map'
import Search from '../components/Search'
import Stats from '../components/Stats'
import Wizard from '../components/Wizard'
import ContentWrapper from '../components/ContentWrapper'
import SearchContext from '../lib/searchContext'

const DashboardView = () => (
  <Fragment>
    <SearchContext.Consumer>
      {({ tags, query }) => (
        <ContentWrapper
          style={{
            display: 'grid',
            gridGap: '20px'
          }}
        >
          <Wizard />
          <Stats />
          <Search />
          <div style={{ position: 'relative', height: '400px' }}>
            <Map tags={tags} query={query}/>
          </div>
        </ContentWrapper>
      )}
    </SearchContext.Consumer>
  </Fragment>
)

export default DashboardView
