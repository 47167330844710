import React from 'react'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { Mutation, Query } from 'react-apollo'
import createTagTypeMutation from './createTagType.graphql'
import removeTagTypeMutation from './removeTagType.graphql'
import query from './tagTypes.graphql'
import TagTypes from './TagTypes'

const tagTypesCacheUpdate = (
  cache,
  { data: { createTagType: { tagType } = {} } }
) => {
  const { tagTypes } = cache.readQuery({ query })
  const sortedTagTypes = sortBy(tagTypes.concat([tagType]), 'name')
  if (tagType) {
    cache.writeQuery({
      query,
      data: { tagTypes: sortedTagTypes }
    })
  }
}

const tagTypesCacheUpdateRemoved = (
  cache,
  { data: { removeTagType: { tagType } = {} } }
) => {
  const { tagTypes } = cache.readQuery({ query })
  if (tagType) {
    cache.writeQuery({
      query,
      data: { tagTypes: tagTypes.filter(({ id }) => tagType.id !== id) }
    })
  }
}

export default rest => (
  <Query query={query}>
    {props =>
      !props.loading && (
        <Mutation
          mutation={removeTagTypeMutation}
          update={(cache, result) => tagTypesCacheUpdateRemoved(cache, result)}
        >
          {removeMutation => (
            <Mutation
              mutation={createTagTypeMutation}
              update={(cache, result) => tagTypesCacheUpdate(cache, result)}
            >
              {createMutation => (
                <TagTypes
                  createMutation={createMutation}
                  removeMutation={removeMutation}
                  tagTypes={get(props, 'data.tagTypes') || []}
                  {...rest}
                />
              )}
            </Mutation>
          )}
        </Mutation>
      )
    }
  </Query>
)
