import React, { Fragment } from 'react'
import { Button } from './index'

const ConfirmDialog = ({ onConfirm, onCancel, dialogContent }) => (
  <Fragment>
    <div className="dialog-content">{dialogContent}</div>
    <div className="dialog-footer" data-cy="dialogFooter">
      <Button onClick={onCancel} variant="secondary" label="Cancel" />
      <Button onClick={onConfirm} label="Confirm" />
    </div>
  </Fragment>
)

export default ConfirmDialog
