import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import UserHeader from './UserHeader'
import UserContext from '../lib/user-context'
import URLS from '../config/urls'

const PrivateRoute = ({
  location: { pathname },
  component: Component,
  ...rest
}) => (
  <UserContext.Consumer>
    {({ user }) => {
      if (user === false) {
        return (
          <Redirect
            to={{
              pathname: URLS.SIGN_IN,
              state: { from: pathname }
            }}
          />
        )
      }
      if (user === true) {
        return (
          <Fragment>
            <UserHeader />
            <Component {...rest} />
          </Fragment>
        )
      }
      return null
    }}
  </UserContext.Consumer>
)

export default withRouter(PrivateRoute)
