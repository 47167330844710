import React, { Component, Fragment } from 'react'
import Input from '../Input'
import tagsQuery from '../Tags/tags.graphql'
import { Badge, NoData } from '../index'

class Tags extends Component {
  state = {
    name: '',
    busy: false,
    error: ''
  }

  inputRef = React.createRef()

  onAddNewTag = async e => {
    e.preventDefault()
    const { busy, name } = this.state
    if (!busy && name) {
      this.setState({ busy: true })
      const { createMutation, tagType } = this.props
      const {
        data: {
          createTag: {
            result: { success, reason }
          }
        }
      } = await createMutation({
        variables: { tagType, name },
        refetchQueries: [{ query: tagsQuery, variables: { tagType } }]
      })
      if (success) {
        this.setState({ name: '', busy: false, error: '' })
      } else {
        this.setState({ name: '', busy: false, error: reason.message })
      }
      this.onFocusInput()
    }
  }

  onChange = name => this.setState({ name })

  onFocusInput = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus()
    }
  }

  renderNoDataContentComponent = () => {
    const onOverrideClick = e => {
      e.preventDefault()
      this.onFocusInput()
    }
    return (
      <Fragment>
        <div className="title">
          Tags list is empty!
        </div>
        <div className="secondary-title">
          <a href="#" onClick={onOverrideClick}>Add your first tag.</a>
        </div>
      </Fragment>
    )
  }

  render () {
    const { tags } = this.props
    const { name, busy, error } = this.state
    return (
      <div className="tags-list" data-cy="tagsList">
        <Fragment>
          {tags.length > 0 ? (
            tags.map(({ name, id }) => (
              <Badge
                key={id}
                label={name}
              />
            ))
          ) : (
            <NoData contentComponent={this.renderNoDataContentComponent} small/>
          )}
        </Fragment>
        <hr />
        <form onSubmit={this.onAddNewTag}>
          <Input
            required
            error={error}
            ref={this.inputRef}
            loading={busy}
            label={null}
            value={name}
            placeholder="Add new tag..."
            onChange={value => this.onChange(value)}
            data-cy="addNewTag"
          />
        </form>
      </div>
    )
  }
}

export default Tags
