import React, { Component, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faTrashAlt,
  faSpinnerThird,
  faCheck,
  faTag,
  faEllipsisH
} from '@fortawesome/pro-regular-svg-icons'
import {
  TAGS_LENGTH_ERROR_MESSAGE,
  DEFAULT_ERROR_TIMEOUT,
  TITLE_EDIT,
  TITLE_DELETE,
  TITLE_SAVE_CHANGES,
  TITLE_MANAGE_TAGS
} from '../../config/constants'
import { Dialog, ConfirmDialog } from '../index'
import Tags from '../Tags'
import TagsEditDialog from '../TagsEditDialog'
import './index.less'

class TagType extends Component {
  state = {
    name: this.props.name,
    editable: false,
    error: ''
  }

  inputRef = React.createRef()

  onChange = ({ target: { value } }) => {
    if (value.length > 0) {
      this.setState({ name: value })
    } else {
      this.setState({ error: TAGS_LENGTH_ERROR_MESSAGE })
      setTimeout(() => this.setState({ error: '' }), DEFAULT_ERROR_TIMEOUT)
    }
  }

  onUpdate = async () => {
    const { updateMutation, id, name: defaultName } = this.props
    const { name } = this.state
    if (name && id && defaultName !== name) {
      await updateMutation({ variables: { id, name } })
      this.setState({ editable: false })
    }
    this.setState({ editable: false })
  }

  onToggleEditable = () => {
    this.setState(
      ({ editable }) => {
        if (!editable) {
          return {
            editable: true
          }
        }
        return {
          editable: false,
          name: this.props.name
        }
      },
      () => {
        if (this.inputRef.current) {
          this.inputRef.current.focus()
        }
      }
    )
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.onUpdate()
    }
  }

  onBlur = () => setTimeout(() => {
    if (this.state.editable) {
      this.onToggleEditable()
    }
  }, 1000)

  render () {
    const { id, loading, onRemoveTagType } = this.props
    const { name, error, editable } = this.state
    return (
      <div className="card tag-type-card shadow-sm">
        <div className="tag-type-header">
          {editable ? (
            <input
              ref={this.inputRef}
              value={name}
              type="text"
              onKeyPress={this.handleKeyPress}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className="tag-type-name"
              data-cy="tagTypeName"
            />
          ) : (
            <div className="card-header" data-cy="cardHeader">
              <FontAwesomeIcon icon={faTag} className="icon" />
              {name}
            </div>
          )}
          {error && <span>{error}</span>}
          {loading && (
            <div className="tiny-loader">
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </div>
          )}

          <div className="controls">
            {editable ? (
              <Fragment>
                <span onClick={this.onUpdate} title={TITLE_SAVE_CHANGES}>
                  <FontAwesomeIcon icon={faCheck} className="icon" />
                </span>
              </Fragment>
            ) : (
              <Fragment>
                <span onClick={this.onToggleEditable} title={TITLE_EDIT}>
                  <FontAwesomeIcon icon={faEdit} className="icon" />
                </span>
                <Dialog
                  title="Remove tag category"
                  contentComponent={({ onToggleDialog }) => (
                    <ConfirmDialog
                      onConfirm={onRemoveTagType}
                      onCancel={onToggleDialog}
                      dialogContent="Are you sure you want to remove this category?"
                    />
                  )}
                >
                  {({ onToggleDialog }) => (
                    <span
                      title={TITLE_DELETE + ` ${name} Tag Type`}
                      onClick={() => {
                        onToggleDialog()
                        this.setState({ editable: false })
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} className="icon" />
                    </span>
                  )}
                </Dialog>
              </Fragment>
            )}
          </div>
        </div>
        <div className="card-body" data-cy="cardBody">
          <Dialog
            title="Tags Manage"
            contentComponent={() => <TagsEditDialog tagType={id} />}
          >
            {({ onToggleDialog }) => (
              <strong
                title={TITLE_MANAGE_TAGS}
                className="card-title tags-manage"
                onClick={onToggleDialog}
              >
                Tags <FontAwesomeIcon icon={faEllipsisH} />{' '}
              </strong>
            )}
          </Dialog>

          <div className="card-text">
            <Tags tagType={id} />
          </div>
        </div>
      </div>
    )
  }
}

export default TagType
