import React, { Fragment } from 'react'
import ContentWrapper from '../ContentWrapper'
import SeoContent from '../SeoContent'
import URLS from '../../config/urls'
import { Link } from 'react-router-dom'
import logo from './logo.png'
import leasPicOne from './leasPicOne.jpeg'
import screenMac from './screenMac.png'
import leasPicThree from './leasPicThree.jpeg'
import leasBigPic from './leasBigPic.jpeg'
import './index.less'

const APP_URL = process.env.REACT_APP_BASE_URL
const seo = {
  title: 'Leasing and Rental GPS Tracking System | Asset Tracking | EcoTrack',
  description:
    'Battery powered stick & track GPS system for rental fleets of all sizes - Whether you lease cars, trucks, trailers, or construction machines, use EcoTrack to manage your fleet by understanding equipment dormancy and utilization, monitor on-site assets, and ensure a constant GPS location of fleet assets.',
  url: `${APP_URL}${URLS.LEASING}/`,
  image: APP_URL + logo
}

const Leasing = () => (
  <Fragment>
    <ContentWrapper>
      <SeoContent {...seo} />
      <div className="bg-leasing">
        <h1 className="display-3 text-light text-center h1-landing">Leasing</h1>
      </div>
      <hr className="line-center" />
      <h2 className="h2-landing text-secondary">Asset Tracking for Lease & Rental Fleets</h2>
      <p className="p-landing text-center mt-4">
      Whether you lease cars, semi trucks and trailers or construction equipment you need to know where every vehicle or piece of equipment is at all times.
      Who could blame you? You’re exactly the reason we launched EcoTracks’s GPS Equipment Lease Tracking solution.<br/>An easy, low cost way to keep up with your rental fleet.
      </p>
      <div className="leasing-one">
        <div className="leas-truck">
          <h3 className="h3-landing px-5 text-dark">Wherever your truck, vehicle or piece of equipment goes, we go to.</h3>
          <p className="p-landing">
          Through EcoTrack, you’re able to not only use its low cost fleet tracking satellite technology to monitor
          the locations of your assets, but you’re able to use this information to calculate things like dormancy rates (how long a vehicle or piece of equipment has sat dormant and where) or
          utilization rate (how often the asset is in the field and working) enabling you to track fundamentally important fleet data points.</p>
        </div>
        <div className="leas-pic-one">
          <img className="img-fluid rounded" src={leasPicOne} alt=""/>
        </div>
      </div>
      <h3 className="h3-landing text-dark">Battery Powered - Lease & Rental Fleet Tracking System - GPS Asset Tracking</h3>
      <p className="p-landing">
        By using the EcoTrack dashboard, you’re able to monitor the status of any asset in your fleet, track utilization and dormancy, track tasks & reminders and retrieve equipment locations;
        all powered by EcoTrack’s simple Fleet Management System.
        We want to make sure that any under-utilized vehicles are repurposed to increase your revenue instead of gathering dust.
      </p>
      <div className="leasing-two">
        <div className="leas-pic-two">
          <img className="img-fluid rounded" src={screenMac} alt=""/>
        </div>
        <div className="leas-data">
          <h3 className="h3-landing px-5 text-dark">Data Share</h3>
          <p className="p-landing">
          While teamwork is paramount in a logistics focused company, communication is the thread that holds teams and companies together. By using the EcoTrack line of leased equipment tracking solutions,
          you have the ability to share data related to specific assets with select 3rd parties at your finger tips.
          Share your data with the teams and subcontractors that need it most by grouping and tagging your inventory and equipment based on who needs visibility.
          </p>
          <div className="middle-page-btn">
            <Link to={URLS.SIGN_UP} className="btn btn-primary btn-lg p-3">Get started with EcoTrack</Link>
          </div>
        </div>
      </div>
      <h3 className="h3-landing text-dark">In Case of Emergency</h3>
      <p className="p-landing">
      One important part of using an EcoTrack Equipment Rental Tracker is providing one more piece of safety equipment for our customers and their employees. In case of emergency, your equipment and its operator
      can be easily located with an increased ability to provide all necessary assistance quicker and more efficiently than ever. Even if a vehicle or piece of equipment is stolen,
      our Geofencing features will alert all necessary parties immediately, allowing for a quick response. Customers can also use EcoTrack to create and modify routes that not only move your equipment and vehicles to different job sites efficiently,
      but provide the safest route to any destination as well.</p>
      <div className="my-5">
        <img className="leas-big-picture rounded" src={leasBigPic} alt=""/>
      </div>
      <h3 className="h3-landing text-dark">Stick and Track - No headache simple set up</h3>
      <p className="p-landing">
          By using two cell and two way batteries in our hardware packs, customers receive an EcoTracker with a battery that will provide up to 10 years of service in almost any condition you could put it through.
          We believe adaptability is one of the cornerstones of a successful business and that’s why we provide our customers with a product that can operate independent of a vehicle’s power system.
          This way the EcoTrack will be providing data no matter what happens to the truck or it’s electrical system.
          Whether you track a small asset group or an entire rental fleet, we have you and your leasing company covered.
      </p>
      <div className="leasing-three">
        <div className="leas-pic-three">
          <img className="img-fluid rounded" src={leasPicThree} alt=""/>
        </div>
        <div className="leas-goal">
          <h3 className="h3-landing text-dark">Dormancy Rates</h3>
          <p className="p-landing">Our goal with EcoTrack is to decrease dormancy rates and increase utilization for every one of our customers across every asset class. That’s why our product enables companies to track all vehicle and equipment types including:
          heavy equipment rental tracking, construction equipment rentals and telematics vehicle tracking. At base level, EcoTrack is a tool that was designed to help companies and their employees do their jobs easier.
          So browse our newest technology and find a GPS tracking solution that works best for you.</p>
        </div>
      </div>
    </ContentWrapper>
  </Fragment>
)
export default Leasing
