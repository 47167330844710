import React, { Fragment } from 'react'
import AssetsTableHeader from './AssetsTableHeader'
import AssetsTableRow from './AssetsTableRow'

const AssetsTable = ({ assets }) => (
  <Fragment>
    <AssetsTableHeader />
    {assets.map(asset => <AssetsTableRow key={asset.id} {...asset} tags={asset.tags.filter(Boolean)} />)}
  </Fragment>
)

export { AssetsTableHeader }
export default AssetsTable
