import React, { Fragment } from 'react'
import ContentWrapper from '../ContentWrapper'
import SeoContent from '../SeoContent'
import URLS from '../../config/urls'
import { Link } from 'react-router-dom'
import logo from './logo.png'
import finPicOne from './finPicOne.jpg'
import finBigPic from './finBigPic.jpg'
import finPicTwo from './finPicTwo.jpg'
import './index.less'

const APP_URL = process.env.REACT_APP_BASE_URL
const seo = {
  title: 'Bank Owned GPS Asset Tracking | Lender Tracking Solutions | EcoTrack',
  description:
    'A simple and effect asset tracking solution that helps lender’s manage risk without breaking the bank. Manage lender’s risk while gaining insight into borrower performance. As a financier or lender, tracking financed assets with EcoTrack can help reduce risk by increasing asset visibility while providing valuable customer data, informing future investments.',
  url: `${APP_URL}${URLS.FINANCING}/`,
  image: APP_URL + logo
}

const Financing = () => (
  <Fragment>
    <ContentWrapper>
      <SeoContent {...seo} />
      <div className="bg-financing">
        <h1 className="display-3 text-light text-center h1-landing">Financing</h1>
      </div>
      <hr className="line-center" />
      <h2 className="h2-landing text-secondary">Bank Owned Asset Tracking via GPS for Lenders and Financiers</h2>
      <p className="p-landing text-center mt-4">
      As a lender or financier, you help business owners start and grow their companies. But, to continue this support and manage risk, having tracked location data on financed assets is critical.
      Understanding where financed equipment is and how long it has been there can help reduce risk and improve a lender’s ability to plan future investments. You’re exactly the reason we launched EcoTracks’s
      GPS Risk Management Solution. An easy, low cost way to keep up with financed bank owned equipment.
      </p>
      <div className="financing-one">
        <div className="fin-pic-one">
          <img className="img-fluid rounded" src={finPicOne} />
        </div>
        <div className="financing-equipment">
          <h3 className="h3-landing px-5 text-dark">Wherever your finances equipment goes (whether truck, trailer, construction equipment, or other asset class), we go too.</h3>
          <p className="p-landing">
          Through EcoTrack, lender’s are able to use its low cost equipment tracking satellite technology to monitor the locations of equipment, as well as calculate things like dormancy rate (how long a vehicle or piece of equipment
          has sat dormant and where) or utilization rate (how often the asset is in the field and working). This allows lender’s to track fundamentally important data points that point towards the success of a borrower’s business venture.
          Gaining an insight into a borrower’s successes or struggles can help underwriters better assess risk, protecting both lender and borrower.</p>
        </div>
      </div>
      <h3 className="h3-landing text-dark">Battery Powered - Simple Install - Manage Risk</h3>
      <p className="p-landing text-center">It isn’t pleasant, but it’s a necessary part of your business: repossession. You did your part. You approved financing for a vehicle or piece of equipment and it looked like a sure bet.
      But, things happen and you need a way to recover your assets. With EcoTrack, you have the ability to know where your assets are anywhere in the world. Allow us to take the guess work out of retreival.
      </p>
      <div className="fin-big-pic-div">
        <img className="big-picture rounded" src={finBigPic} alt=""/>
      </div>
      <div className="fin-two">
        <div className="fin-lend-cov">
          <h3 className="h3-landing text-dark">Lending Covenants</h3>
          <p className="p-landing">
          When financing equipment and vehicles, there hasn’t always been an easy way to make sure the borrower is compliant with your specific lending covenants. If you have a location covenant for example,
          EcoTrack can easily verify that the equipment you financed has stayed within your required geographic limits. With EcoTrack you can easily create a geofence as wide or as narrow as you require.
          As soon as your asset has crossed the line, you’re immediately alerted and can quickly determine your next course of action.
          </p>
        </div>
        <div className="fin-pic-two">
          <img className="img-fluid rounded" src={finPicTwo} />
        </div>
      </div>
      <div className="fin-stick">
        <h3 className="h3-landing px-5 text-dark">Stick and Track - No headache simple setup.</h3>
        <p className="p-landing">
          By using two cell and two way batteries in our hardware packs, customers receive an EcoTracker with a battery will provide up to 10 years of service in almost any condition you could put it through.
          We believe adaptability is one of the cornerstones of a successful business and that’s why we provide our customers with a product that can operate
          independent of a power source. This way the EcoTrack will be providing data no matter what happens to vessel or is electrical system while in transit.
          Whether you track one small asset group or a fleet of maritime vessels, we have you and your logistics company covered.</p>
      </div>
      <div className="fin-three">
        <div className="fin-price-div">
          <h2 className="h2-button">Get started with EcoTrack</h2>
          <Link to={URLS.PRICING} className="btn btn-light price-button">Check our prices</Link>
        </div>
        <div className="fin-goals">
          <h3 className="h3-landing text-dark">Our Goals</h3>
          <p className="p-landing">Our goal with EcoTrack is to decrease dormancy rates and increase utilization for every one of our customers across every asset class. That’s why our customers use
          EcoTrack to access equipment management software, repossession asset tracking and financed equipment tracking. At base level, EcoTrack is a tool that was designed to help lenders and banks.
          So browse our newest technology and find a GPS tracking solution that works best for you.</p>
        </div>
      </div>
    </ContentWrapper>
  </Fragment>
)
export default Financing
