import React from 'react'
import Assets from './Assets'
import AssetsWithQuery from '../../components/AssetsWithQuery'
import SearchContext from '../../lib/searchContext'
import { withRouter } from 'react-router-dom'

const QueriedAssets = props => (
  <SearchContext.Consumer>
    {({ tags, onSetTags, query }) => {
      return (
        <AssetsWithQuery variables={{ tags, query }}>
          {({ data: { assets = [] } = {}, loading }) => (
            <Assets
              assets={assets}
              loading={loading}
              {...props}
              tags={tags}
              onSetTags={onSetTags}
            />
          )}
        </AssetsWithQuery>
      )
    }}
  </SearchContext.Consumer>
)

export default withRouter(QueriedAssets)
