import moment from 'moment'
import { DEFAULT_DATE_FORMAT } from '../config/constants'
import { first, isEmpty, isEqual } from 'lodash'

export const isTypeName = key => key === '__typename'

export const formatDate = (date, format = DEFAULT_DATE_FORMAT) =>
  moment(parseInt(date) || date).format(format)

export const urlQueryUpdate = ({ search, history, options, keyName }) => {
  const qs = new URLSearchParams(search)
  const searchParams = getValuesFromUrl({ keyName, search })

  if (!isEqual(options, searchParams)) {
    if (!options || isEmpty(options)) {
      qs.delete(keyName)
    } else {
      qs.set(keyName, (options))
    }
    history.push({
      search: qs.toString()
    })
  }
}

export const getValuesFromUrl = ({ keyName, search }) => {
  const qs = new URLSearchParams(search)
  return (first(qs.getAll(keyName)) || '').split(',').filter(Boolean)
}
