import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapPin, faPhoneSquare, faEnvelopeSquare } from '@fortawesome/pro-regular-svg-icons'
import Input from '../Input'
import './index.less'

const ContactForm = () => (
  <div className="ContactFormView container">
    <div className="row mt-5">
      <div className="col">
        <h2><FontAwesomeIcon icon={faPhoneSquare} size="1x" /> Phone</h2>
        <p>770-365-6151</p>
      </div>
      <div className="col">
        <h2><FontAwesomeIcon icon={faMapPin} size="1x" /> Address</h2>
        <p>701 Brazos St, Austin, TX 78701</p>
      </div>
      <div className="col">
        <h2><FontAwesomeIcon icon={faEnvelopeSquare} size="1x" /> E-mail</h2>
        <p>info@murdochgrouplimited.com</p>
      </div>
    </div>
    <form className="mt-5">
      <Input type="text" value="" label="Name"/>
      <Input type="text" value="" label="Surname"/>
      <Input type="email" value="" label="Email Address"/>
      <Input type="text" value="" label="Company"/>
      <div className="Input form-group textArea">
        <label>Label</label>
        <textarea className="form-control" rows="4" placeholder="Your message"></textarea>
      </div>
      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
  </div>
)

export default ContactForm
