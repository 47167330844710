import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import mutation from './mutation.graphql'
import SignInForm from './form'
import { notify } from '../Notifications'
import UserContext from '../../lib/user-context'

export default class SignIn extends Component {
  state = {
    email: '',
    password: '',
    errors: {}
  };

  static contextType = UserContext

  handleSubmit = mutation => async e => {
    const { email, password } = this.state
    e.preventDefault()
    const { data: { signIn: { token, result: { success, reason } } } } = await mutation({ variables: {
      email,
      password
    } })
    if (!success) {
      this.setState({ errors: { password: reason.message } })
      return notify(reason.message, 'danger')
    }
    this.context.signIn({ token })
  };

  onChange = field => value => {
    this.setState({ errors: {}, [field]: value })
  };

  handleKeyPress = mutation => event => {
    if (event.key === 'Enter') {
      this.handleSubmit(mutation)(event)
    }
  };

  render () {
    return (
      <Mutation mutation={mutation}>
        {mutation => <SignInForm onSubmit={this.handleSubmit(mutation)} onKeyPress={this.handleKeyPress(mutation)} onChange={this.onChange} {...this.state} />}
      </Mutation>
    )
  }
}
