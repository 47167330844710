import React from 'react'
import { Mutation } from 'react-apollo'
import updateTagMutation from './updateTag.graphql'
import removeTagMutation from './removeTag.graphql'
import tagsQuery from '../Tags/tags.graphql'
import Tag from './Tag'

const tagCacheUpdateRemove = (cache, { data: { removeTag: { tag } = {} } }) => {
  if (tag) {
    const { tagType: { id: tagType } = {} } = tag
    const { tags } = cache.readQuery({
      query: tagsQuery,
      variables: { tagType }
    })
    if (tags && tags.length) {
      cache.writeQuery({
        query: tagsQuery,
        data: { tags: tags.filter(({ id }) => id !== tag.id) }
      })
    }
  }
}

export default rest => (
  <Mutation mutation={updateTagMutation}>
    {updateMutation => (
      <Mutation
        mutation={removeTagMutation}
        update={(cache, result) => tagCacheUpdateRemove(cache, result)}
      >
        {removeMutation => (
          <Tag
            updateMutation={updateMutation}
            removeMutation={removeMutation}
            {...rest}
          />
        )}
      </Mutation>
    )}
  </Mutation>
)
