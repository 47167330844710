import React, { Fragment } from 'react'
import SignUp from '../components/SignUp'
import ContentWrapper from '../components/ContentWrapper'

const SignUpView = () => (
  <Fragment>
    {/* TODO: compact header here */}
    <ContentWrapper>
      <div className="row justify-content-md-center">
        <div className="col col-md-5 col-lg-4">
          <h1 className="display-4">Sign Up</h1>
          <SignUp />
        </div>
      </div>
    </ContentWrapper>
  </Fragment>
)

export default SignUpView
