import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import mutation from './mutation.graphql'
import ResetForm from './form'
import { notify } from '../Notifications'

export default class Reset extends Component {
  state = {
    email: '',
    errors: {},
    completed: false
  };

  handleSubmit = mutation => async e => {
    const { email } = this.state
    e.preventDefault()
    const { data: { passwordReset: { success, reason } } } = await mutation({ variables: { email } })
    if (!success) {
      this.setState({ errors: { email: reason.message } })
      return notify(reason.message, 'danger')
    }
    this.setState({ completed: true })
  };

  onChange = field => value => {
    this.setState({ errors: {}, [field]: value })
  };

  handleKeyPress = mutation => event => {
    if (event.key === 'Enter') {
      this.handleSubmit(mutation)(event)
    }
  };

  render () {
    if (this.state.completed) {
      return (
        <p>Check your email now and follow instructions.</p>
      )
    }
    return (
      <Mutation mutation={mutation}>
        {mutation => <ResetForm onSubmit={this.handleSubmit(mutation)} onKeyPress={this.handleKeyPress(mutation)} onChange={this.onChange} {...this.state} />}
      </Mutation>
    )
  }
}
