import React, { Fragment } from 'react'
import ContentWrapper from '../ContentWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAltSlow, faUsers, faClipboardList, faGlobeAmericas, faTags, faShareAll } from '@fortawesome/pro-light-svg-icons'
import softPicOne from './softPicOne.jpg'
import './index.less'

const SoftwareFeat = () => (
  <Fragment>
    <ContentWrapper>
      <div className="bg-soft-feat">
        <h1 className="h1-landing display-3 text-light text-center p-5">Software Features</h1>
        <div className="header-hardware">
          <hr className="line-center" />
          <h2 className="h2-feat text-secondary text-center">General Info</h2>
        </div>
        <div className="software-top">
          <div className="soft-pic-one">
            <img className="img-fluid rounded" src={softPicOne} alt=""/>
          </div>
          <div className="soft-p-one">
            <p className="p-landing">
            With EcoTrack, you can manage your assets through our Tracking Dashboard using maps for asset pinning and a user management system.
            Go one level deeper and create category tags and groupings,
            set reminders, create asset specific notes and share location data with other stakeholders or partners.
            </p>
          </div>
        </div>
        <h2 className="h2-spec text-center text-light">Key Features</h2>
        <div className="software-content">
          <div className="soft-cont-one">
            <h3 className="soft-key-feat"><FontAwesomeIcon icon={faTachometerAltSlow} /> Asset Tracking Dashboard</h3>
            <p className="p-landing">A quick overview that shows all your asset and their current locations.
            Filter my custom tags and groups to get quick and easy insight into your fleet.</p>
          </div>
          <div className="soft-cont-two">
            <h3 className="soft-key-feat"><FontAwesomeIcon icon={faUsers} /> User Management System</h3>
            <p className="p-landing">Create an unlimited number of users for your company so that all layers of the organization are in the know when it comes to company equipment in the field.</p>
          </div>
          <div className="soft-cont-three">
            <h3 className="soft-key-feat"><FontAwesomeIcon icon={faClipboardList} /> Reminders & Notes</h3>
            <p className="p-landing">Create simple but powerful notes and reminds to help you in keeping up with all things related to your fleet.</p>
          </div>
          <div className="soft-cont-four">
            <h3 className="soft-key-feat"><FontAwesomeIcon icon={faGlobeAmericas} /> Geo-fencing Maps</h3>
            <p className="p-landing">Create imaginary boundaries at specific map locations to help in notifying you of enters, exits, or current locations of any given Geo-Fence and asset.
            Track details about customer yards, terminals, ports, or anything else important to your business.</p>
          </div>
          <div className="soft-cont-five">
            <h3 className="soft-key-feat"><FontAwesomeIcon icon={faTags} /> Category Tags & Grouping</h3>
            <p className="p-landing">Create customer groups and tags, so that you can organize your equipment as you see fit for your business. Use these tags to search assets, run reports, extend user access and more.</p>
          </div>
          <div className="soft-cont-six">
            <h3 className="soft-key-feat"><FontAwesomeIcon icon={faShareAll} /> Outside User Sharing</h3>
            <p className="p-landing">Use custom tags and groups to extend limited access to other users outside your organization including customer, lenders, vendors, terminal managers, and regional department heads.</p>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </Fragment>
)

export default SoftwareFeat
