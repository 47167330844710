import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import classnames from 'classnames'
import shortId from 'shortid'
import './index.less'

const Input = React.forwardRef(
  (
    {
      className,
      disabled,
      error,
      helptext,
      inline,
      inputClassName,
      label,
      labelClassName,
      loading,
      onChange,
      placeholder,
      type,
      value,
      name,
      ...rest
    },
    ref
  ) => {
    const id = shortId()
    const onOverrideChange = ({ target: { value, dataset } = {} } = {}) => {
      if (onChange) {
        onChange(value, dataset)
      }
    }

    const overrideClassNames = classnames('Input form-group', className, {
      'has-value': !!value,
      inline: !!inline
    })
    const overrideLabelClassNames = classnames(labelClassName, {
      'animated fadeInUp': !!value
    })
    const overrideInputClassNames = classnames('form-control', inputClassName, {
      'is-invalid': !!error
    })

    return (
      <div className={overrideClassNames}>
        {label && (
          <label className={overrideLabelClassNames} htmlFor={id}>
            {label}
          </label>
        )}
        <input
          className={overrideInputClassNames}
          {...rest}
          onChange={onOverrideChange}
          ref={ref}
          id={id}
          type={type}
          value={value}
          placeholder={placeholder || label}
          disabled={disabled || loading}
          data-name={name}
        />
        {loading && (
          <FontAwesomeIcon icon={faSpinnerThird} spin className="spin-icon" />
        )}
        {(error || helptext) && (
          <small className={`form-text text-${error ? 'danger' : 'muted'}`}>
            {error || helptext}
          </small>
        )}
      </div>
    )
  }
)

Input.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helptext: PropTypes.string,
  inline: PropTypes.bool,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string
}

Input.defaultProps = {
  disabled: false,
  error: '',
  helptext: '',
  inline: false,
  inputClassName: '',
  label: 'Label',
  labelClassName: '',
  loading: false,
  placeholder: '',
  type: 'text',
  value: ''
}

export default Input
