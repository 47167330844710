import React from 'react'
import { Mutation } from 'react-apollo'
import TagType from './TagType'
import updateTagTypeMutation from './updateTagType.graphql'
import tagTypesQuery from '../TagTypes/tagTypes.graphql'

const tagTypeCacheUpdate = (
  cache,
  { data: { updateTagType: { tagType } = {} } }
) => {
  const { tagTypes } = cache.readQuery({ query: tagTypesQuery })
  const selectedTagType = tagTypes.find(({ id }) => id === tagType.id)
  const selectedTagTypeIndex = tagTypes.findIndex(
    ({ id }) => id === tagType.id
  )

  if (tagType && selectedTagType) {
    tagTypes[selectedTagTypeIndex].name = tagType.name
    cache.writeQuery({
      query: tagTypesQuery,
      data: { tagTypes }
    })
  }
}

export default props => (
  <Mutation
    mutation={updateTagTypeMutation}
    update={(cache, result) => tagTypeCacheUpdate(cache, result)}
  >
    {(updateMutation, { loading }) => (
      <TagType updateMutation={updateMutation} loading={loading} {...props} />
    )}
  </Mutation>
)
