import React, { Fragment, useState } from 'react'
import startCase from 'lodash/startCase'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import AssetsSidebarContent from '../AssetsSidebarContent'
import { formatDate } from '../../lib/index'
import AssetTrackingFilters from './AssetTrackingFilters'
import { Button, NoData } from '../index'
import { FILTER_TYPES, US_DATE_FORMAT } from '../../config/constants'

const fields = ['make', 'model', 'externalNo', 'referenceNo']

const AssetTrackingSideBar = (props) => {
  const { name, tags, activities, onActivityClick, onChange, activeFilter, filters, selectedActivityId } = props

  const [ open, onSetOpen ] = useState(true)
  const [ editableMode, onSetEditableMode ] = useState(false)

  const getFiltersValue = () => {
    if (activeFilter === FILTER_TYPES.RANGE) {
      return `From: ${formatDate(filters.from, US_DATE_FORMAT)} | To: ${formatDate(filters.to, US_DATE_FORMAT)}`
    }

    switch (activeFilter) {
      case FILTER_TYPES.DAYS7: return 'Last 7 Days'
      case FILTER_TYPES.DAYS14: return 'Last 14 Days'
      case FILTER_TYPES.DAYS30: return 'Last 30 Days'
    }
  }

  return (
    <div className={classNames('asset-tracking-sidebar', { open })}>
      <span className="hide-button" onClick={() => onSetOpen(!open)}>
        <FontAwesomeIcon className="icon" icon={open ? faChevronLeft : faChevronRight} />
      </span>
      <div className="header-wrapper">
        <Button className="edit-button" onClick={() => onSetEditableMode(!editableMode)} label="Edit" variant="primary"/>
        <h3 className="asset-name">{name}</h3>
        <hr/>
        {!!tags.length && (
          <div className="tags">
            {tags.map(({ name }) => (
              <span key={name} className="badge badge-primary">{name}</span>
            ))}
          </div>
        )}
      </div>
      <div className="scroll-wrapper">
        {editableMode ? (
          <div>
            <AssetsSidebarContent {...props} onClose={() => onSetEditableMode(false)} />
          </div>
        ) : (
          <Fragment>
            <AssetTrackingFilters onChange={onChange} activeFilter={activeFilter} filters={filters} />
            {fields.length > 4 && (
              <Fragment>
                <dl>
                  {fields.map(fieldName => {
                    const fieldValue = fieldName === 'created'
                      ? formatDate(props[fieldName])
                      : props[fieldName]

                    return fieldValue && (
                      <Fragment key={fieldName}>
                        <dt>{startCase(fieldName)}</dt>
                        <dd>{fieldValue}</dd>
                      </Fragment>
                    )
                  })}
                </dl>
                <hr />
              </Fragment>
            )}
            {(activities && !!activities.length) ? (
              <div className="history">
                <h6>History</h6>
                <small className="history-filter-caption">{getFiltersValue()}</small>
                <div className="activities-history">
                  {activities.map(({ id, date }) => (
                    <div className={classNames('activity-item', { selected: id === selectedActivityId })} key={id} onClick={() => onActivityClick(id, date)}>
                      {date}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <NoData />
            )}
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default AssetTrackingSideBar
