import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTag,
  faChevronRight,
  faChevronUp
} from '@fortawesome/pro-regular-svg-icons'
import { Checkbox } from '../index'
import './index.less'

class TagTypesDropDownOptions extends Component {
  state = {
    open: this.props.forceOpen
  }

  onToggleOpen = () => this.setState({ open: !this.state.open })

  render () {
    const { open } = this.state
    const { name, tags, selected, onSelect, onSelectAllInTagType } = this.props
    const allTagsIds = tags.map(({ id }) => id)
    const allTagIdInSelectedIndex = allTagsIds
      .filter(tagId => selected.find(s => (s.id || s) === tagId)).filter(Boolean).length

    return (
      <Fragment>
        <h3
          className="dropdown-header tag-types-drop-down-header"
          onClick={this.onToggleOpen}
        >
          <span>
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronRight} />
          </span>
          <span>
            <span className="mr-2">{name}</span>
            <span className="text-primary">({allTagIdInSelectedIndex})</span>
          </span>
          <Checkbox
            onChange={() => onSelectAllInTagType(allTagsIds, tags)}
            onClick={e => e.stopPropagation()}
            checked={allTagIdInSelectedIndex === allTagsIds.length}
          />
        </h3>
        <div
          className={classNames('tag-types-drop-down-list', {
            open: !!open
          })}
        >
          {tags.map(({ id: tagId, name: tagName }) => (
            <div
              className="dropdown-item tag-types-drop-down-list-item"
              key={tagId}
              onClick={() => onSelect(tagId, tagName)}
            >
              <FontAwesomeIcon icon={faTag} />
              <span className="mr-3">{tagName}</span>
              <Checkbox checked={!!selected.find(s => (s.id || s) === tagId)} />
            </div>
          ))}
        </div>
      </Fragment>
    )
  }
}

TagTypesDropDownOptions.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
}

export default TagTypesDropDownOptions
