import React from 'react'
import { Link } from 'react-router-dom'
import AssetsWithQuery from '../AssetsWithQuery'
import URLS from '../../config/urls'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'

const TrackingStats = () => (
  <div className="card">
    <h5 className="card-header" data-cy="trackingStatsHeader">Currently Tracking</h5>
    <div className="card-body">
      <AssetsWithQuery>
        {({ data: { assets = [] } = {}, loading }) => (
          !loading ? (
            <p><Link to={URLS.ASSETS} style={{ fontSize: '200%' }}>
              {assets.length} <span className="text-muted"> assets </span>
            </Link></p>
          ) : (
            <p className="text-muted" style={{ fontSize: '200%' }}>
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </p>
          )
        )}
      </AssetsWithQuery>
      <Link
        to={{
          pathname: URLS.ASSETS,
          state: {
            focusOnAddInput: true
          }
        }}
        className="card-link"
      >
        Add New
      </Link>
    </div>
  </div>
)

export default TrackingStats
