import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import TagTypesWithTags from '../TagTypesWithTags'
import { DropDown, DropDownHeader } from '../index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import TagTypesDropDownOptions from '../TagTypesDropDownOptions'
import { urlQueryUpdate } from '../../lib'

class SearchTagTypes extends Component {
  state = {}
  static getDerivedStateFromProps ({ location: { search }, history, tags }, state) {
    urlQueryUpdate({ search, history, options: tags, keyName: 'tags' })
    return state
  }

  onSelect = id => {
    const { tags } = this.props
    const isInSelected = tags.find(tagId => tagId === id)
    let newSelected = []
    if (isInSelected) {
      newSelected = tags.filter(tagId => tagId !== id)
    } else {
      newSelected = [...tags, id]
    }
    this.props.onSetTags(newSelected)
  }

  onSelectAllInTagType = allTagsIds => {
    const { tags } = this.props
    let newSelected = []
    const allTagIdInSelectedIndex = allTagsIds
      .map(tagId => tags.includes(tagId))
      .filter(Boolean).length

    let isAnyTagInSelectedFromTagType = false
    allTagsIds.forEach(tagId => {
      if (tags.includes(tagId)) {
        return (isAnyTagInSelectedFromTagType = true)
      }
    })

    if (allTagIdInSelectedIndex === allTagsIds.length) {
      newSelected = tags.filter(tagId => !allTagsIds.includes(tagId))
    } else if (isAnyTagInSelectedFromTagType) {
      newSelected = [...new Set([...tags, ...allTagsIds])]
    } else {
      newSelected = [...tags, ...allTagsIds]
    }
    this.props.onSetTags(newSelected)
  }

  onSelectAll = allTagsIds => {
    const { tags } = this.props
    const newSelected = allTagsIds.length === tags.length ? [] : allTagsIds

    this.props.onSetTags(newSelected)
  }

  render () {
    const { tags } = this.props
    return (
      <div className="input-group-prepend">
        <TagTypesWithTags>
          {({ tagTypes, allTagsIds, loading }) => (
            <DropDown
              dropDownCustomClass="tag-types-drop-down"
              openComponent={() => (
                <button className="active btn btn-outline-secondary dropdown-toggle" type="button">
                  <FontAwesomeIcon icon={loading ? faSpinnerThird : faFilter} spin={loading} />
                  {!!tags.length && (
                    <span className="tags-counter">{tags.length}</span>
                  )}
                </button>
              )}
            >
              <DropDownHeader
                onSelectAll={() => this.onSelectAll(allTagsIds)}
                checked={allTagsIds.length === tags.length}
              />
              {tagTypes.map((tagType, key) => (
                <TagTypesDropDownOptions
                  key={tagType.id}
                  onSelect={this.onSelect}
                  onSelectAllInTagType={this.onSelectAllInTagType}
                  selected={tags}
                  forceOpen={key === 0}
                  {...tagType}
                />
              ))}
            </DropDown>
          )}
        </TagTypesWithTags>
      </div>
    )
  }
}

export default withRouter(SearchTagTypes)
