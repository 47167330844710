import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.less'

const Button = ({ label, type, variant, disabled, round, onClick, style, icon, iconSpin, className, ...otherProps }) => (
  <button
    style={style}
    className={classnames('button', className, { [variant]: !!variant, round: !!round })}
    type={type}
    onClick={onClick}
    disabled={disabled}
    data-cy="addButton"
    {...otherProps}
  >
    {icon && (
      <FontAwesomeIcon icon={icon} spin={iconSpin}/>
    )}
    {label}
  </button>
)

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  disabled: false,
  round: false,
  onClick: () => {}
}

export default Button
