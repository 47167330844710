import React from 'react'
import PropTypes from 'prop-types'
import { Input, Button, ContentWrapper } from '../index'

const InlineInputAdd = ({
  title,
  value,
  onChange,
  onClick,
  onSubmit,
  busy,
  inputRef,
  inputTitle,
  dataTest
}) => (
  <ContentWrapper>
    <div>
      <form onSubmit={onSubmit || onClick} data-cy={dataTest}>
        <div className="form-row mb-2">
          <h3 className="h3">{title}</h3>
        </div>
        <div className="form-row ">
          <div className="form-group col-sm-12 mb-1 pl-0 pr-0">
            <label className="col-form-label font-weight-bold">
              {inputTitle}{' '}
            </label>
          </div>
          <div className="form-group col-sm-12 mb-1 pl-0 pr-0">
            <Input
              className="mr-2"
              onChange={onChange}
              value={value}
              label={null}
              loading={busy}
              ref={inputRef}
              inline
            />
            <Button label="Add" disabled={!value || busy} onClick={onClick} />
          </div>
        </div>
      </form>
    </div>
  </ContentWrapper>
)

InlineInputAdd.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  busy: PropTypes.bool.isRequired,
  inputTitle: PropTypes.string.isRequired
}

export default InlineInputAdd
