import React, { Component } from 'react'
import { Button } from '../index'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './index.less'

class DropDown extends Component {
  state = {
    open: false
  }

  dropDownRef = React.createRef()

  componentWillUnmount () {
    document.removeEventListener('click', this.onClick)
  }

  onClick = ({ target }) => {
    const { open } = this.state
    if (!open) return
    if ((this.dropDownRef.current && !this.dropDownRef.current.contains(target)) || this.props.closeOnClick) {
      this.setState({ open: false })
      return document.removeEventListener('click', this.onClick)
    }
  }

  onToggleDropDown = () => {
    const { open } = this.state
    if (open) {
      this.setState({ open: false })
      return document.removeEventListener('click', this.onClick, false)
    }

    this.setState({ open: true })
    return document.addEventListener('click', this.onClick, false)
  }

  renderOpenComponent = () => {
    const { openComponent: OpenComponent, openComponentProps } = this.props
    if (OpenComponent) {
      return (
        <span onClick={this.onToggleDropDown}>
          <OpenComponent openComponentProps={openComponentProps} />
        </span>
      )
    }

    return (
      <span onClick={this.onToggleDropDown}>
        <Button openComponentProps={openComponentProps} />
      </span>
    )
  }

  render () {
    const { open } = this.state
    const { dropDownCustomClass, className, style } = this.props
    return (
      <span className={classnames('dropdown-container', className)} style={style}>
        {this.renderOpenComponent()}
        <div
          className={classnames('dropdown-menu', dropDownCustomClass, {
            show: !!open
          })}
          ref={this.dropDownRef}
        >
          {this.props.children}
        </div>
      </span>
    )
  }
}

DropDown.propTypes = {
  openComponent: PropTypes.func,
  openComponentProps: PropTypes.object,
  dropDownCustomClass: PropTypes.string,
  closeOnClick: PropTypes.bool
}

DropDown.defaultProps = {
  openComponent: null,
  openComponentProps: {},
  dropDownCustomClass: ''
}

export default DropDown
