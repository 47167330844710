import { useEffect } from 'react'
import PropTypes from 'prop-types'
import MarkerClusterer from 'node-js-marker-clusterer'
import latestLocationMarker from './marker.png'
import locationMarker from './marker-gray.png'

const MarkerCluster = (props) => {
  const { map, google, markers, icon } = props

  const handleEvent = ({ event, marker, entry }) => {
    if (props[event]) {
      props[event]({
        props: props,
        marker: marker,
        event: event,
        entry: entry
      })
    }
  }

  useEffect(() => {
    if (map && markers) {
      const mapMarkers = markers.map((marker) => {
        const position = new google.maps.LatLng(
          marker.position.lat(),
          marker.position.lng()
        )
        const entry = new google.maps.Marker({
          onClick: marker.onClick,
          position,
          map: map,
          name: marker.name,
          icon: marker.isLatest ? latestLocationMarker : locationMarker
        })

        MarkerCluster.events.forEach(e => {
          entry.addListener(e, () => handleEvent({
            event: e,
            marker: marker,
            entry: entry
          }))
        })

        return entry
      })
      const clusterer = new MarkerClusterer(
        map,
        mapMarkers,
        { imagePath: icon }
      )
      return () => clusterer.clearMarkers()
    }
  }, [map, google, markers])

  return null
}

MarkerCluster.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  markers: PropTypes.array
}

MarkerCluster.events = [
  'click',
  'dblclick',
  'dragend',
  'mousedown',
  'mouseout',
  'mouseover',
  'mouseup',
  'recenter'
]

export default MarkerCluster
