import React from 'react'
import PropTypes from 'prop-types'
import Tag from '../Tag'
import { faFrown } from '@fortawesome/pro-regular-svg-icons'
import { NoData } from '../index'

const TagsEditDialog = ({ tagType, loading, data: { tags } = {} }) => {
  if (loading) return null
  return (
    <div className="modal-body" data-cy="tagsEditDialog">
      {tags.length > 0 ? (
        tags.map(tag => <Tag key={tag.id} tagType={tagType} {...tag} />)
      ) : (
        <NoData
          title="There is nothing to manage!"
          secondaryTitle="Add your first tag and try again."
          icon={faFrown}
          fullWidth
        />
      )}
    </div>
  )
}

TagsEditDialog.propTypes = {
  tagType: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    tags: PropTypes.array
  })
}

export default TagsEditDialog
