import React from 'react'
import { Mutation } from 'react-apollo'
import updateAssetMutation from './updateAsset.graphql'
import removeAssetMutation from './removeAsset.graphql'
import createAssetMutation from './createAsset.graphql'

export default ({ children, ...rest }) => (
  <Mutation mutation={updateAssetMutation}>
    {updateMutation => (
      <Mutation mutation={removeAssetMutation}>
        {removeMutation => (
          <Mutation mutation={createAssetMutation}>
            {createMutation =>
              children({ removeMutation, updateMutation, createMutation, ...rest })
            }
          </Mutation>
        )}
      </Mutation>
    )}
  </Mutation>
)
