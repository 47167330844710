import React from 'react'
import './index.less'

const SideBarHeader = ({ onClose, title }) => (
  <div className="card-header p-3">
    <h5 className="d-inline-block card-title mb-0" data-cy="sidebarTitle">
      {title}
    </h5>
    <div className="close-control" onClick={onClose}>
      <small className="text-muted">esc</small>
      <button type="button" className="close" aria-label="Close" data-cy="closeButtonTimes">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
)

export default SideBarHeader
