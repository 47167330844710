export const URLS = {
  ASSET_TRACKING: '/asset-tracking/:assetId',
  ABOUT_US: '/about-us',
  ANALYTICS: '/analytics',
  ASSETS: '/assets',
  CONTACT: '/contact-us',
  DASHBOARD: '/dashboard',
  FAQ: '/faq',
  HOME: '/',
  PRICING: '/pricing',
  PRIVACY: '/privacy-policy',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_CODE: '/reset/:code1/:code2',
  SETTINGS: '/settings',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  SUPPORT: '/support',
  TERMS: '/terms-of-use',

  LOGISTICS: '/gps-truck-trailer-tracking',
  CONSTRUCTION: '/construction-equipment-gps-tracking',
  SHIPPING_CONTAINER: '/shipping-container-intermodal-gps-tracking',
  LEASING: '/leasing-and-rental-gps-asset-tracking',
  FINANCING: '/gps-tracking-solutions-for-lenders-and-financiers',
  INSURANCE: '/gps-asset-tracking-solution-for-insurance-providers',
  HARDWARE_FEATURES: '/hardware-features',
  SOFTWARE_FEATURES: '/software-features'
}

export const userMenuItems = [
  {
    name: 'Dashboard',
    url: URLS.DASHBOARD
  },
  {
    name: 'Assets',
    url: URLS.ASSETS
  },
  {
    name: 'Analytics',
    url: '#'
  },
  {
    name: 'Settings',
    url: URLS.SETTINGS
  }
]

export const solutionsMenuItems = [
  {
    name: 'Logistics',
    url: URLS.LOGISTICS
  },
  {
    name: 'Construction',
    url: URLS.CONSTRUCTION
  },
  {
    name: 'Shipping Container',
    url: URLS.SHIPPING_CONTAINER
  },
  {
    name: 'Leasing',
    url: URLS.LEASING
  },
  {
    name: 'Financing',
    url: URLS.FINANCING
  },
  {
    name: 'Insurance',
    url: URLS.INSURANCE
  }
]

export const productOverviewMenuItems = [
  {
    name: 'Hardware Features',
    url: URLS.HARDWARE_FEATURES
  },
  {
    name: 'Software Features',
    url: URLS.SOFTWARE_FEATURES
  }
]

export default URLS
