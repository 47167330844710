import Input from '../Input'
import React, { Fragment } from 'react'
import './form.less'
import isEmpty from 'lodash/isEmpty'
import { Link } from 'react-router-dom'
import URLS from '../../config/urls'

const Form = ({ errors, onSubmit, onChange, password, email, passwordConfirmation, onKeyPress }) => (
  <Fragment>
    <form className="Form" onSubmit={onSubmit}>
      <Input type="email" error={errors.email} value={email} onChange={onChange('email')} label="Email Address" />
      <Input type="password" error={errors.password} value={password} onChange={onChange('password')} label="Password" />
      <Input type="password" error={errors.passwordConfirmation} value={passwordConfirmation} onKeyPress={onKeyPress} onChange={onChange('passwordConfirmation')} label="Password Confirmation" />
      <p>
        <button className="mt-2 mr-3 btn btn-secondary disabled">Sign Up with Google</button>
        <button disabled={!isEmpty(errors)} className="mt-2 btn btn-primary">Sign Up</button>
      </p>
    </form>
    <p className="text-muted">
      Do you have an account already? <Link to={URLS.SIGN_IN}> Sign In </Link> instead.
    </p>
  </Fragment>

)

export default Form
