import React from 'react'
import './index.less'

const AssetsTableHeader = () => (
  <div className="assets-table-header">
    <div className="field-title name">Name/Updated At</div>
    <div className="field-title make">Make</div>
    <div className="field-title model">Model</div>
    <div className="field-title external-no">External No</div>
    <div className="field-title geolocation">Geolocation</div>
    <div className="field-title device-id">Device ID</div>
    <div className="field-title status">Status</div>
    <div className="field-title tags">Tags</div>
  </div>
)

export default AssetsTableHeader
