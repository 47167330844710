import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import mutation from './mutation.graphql'
import ResetConfirmationForm from './form'
import { notify } from '../Notifications'
import { Link, withRouter } from 'react-router-dom'
import URLS from '../../config/urls'

class ResetConfirmation extends Component {
  state = {
    password: '',
    errors: {},
    completed: false
  };

  handleSubmit = mutation => async e => {
    e.preventDefault()
    const { password } = this.state
    const { code1, code2 } = this.props.match.params
    const { data: { passwordResetConfirmation: { success, reason } } } = await mutation({ variables: { code1, code2, password } })
    if (!success) {
      this.setState({ errors: { email: reason.message } })
      return notify(reason.message, 'danger')
    }
    this.setState({ completed: true })
  };

  onChange = field => value => {
    this.setState({ errors: {}, [field]: value })
  };

  handleKeyPress = mutation => event => {
    if (event.key === 'Enter') {
      this.handleSubmit(mutation)(event)
    }
  };

  render () {
    if (this.state.completed) {
      return (
        <p>You can now <Link to={URLS.SIGN_IN}>Sign in</Link> to your account.</p>
      )
    }
    return (
      <Mutation mutation={mutation}>
        {mutation => <ResetConfirmationForm onSubmit={this.handleSubmit(mutation)} onKeyPress={this.handleKeyPress(mutation)} onChange={this.onChange} {...this.state} />}
      </Mutation>
    )
  }
}

export default withRouter(ResetConfirmation)
