import React, { Fragment } from 'react'
import classNames from 'classnames'
import ScrollToTop from '../ScrollToTop'
import './index.less'

const ContentWrapper = ({ children, style, className }) => (
  <Fragment>
    <ScrollToTop />
    <div className={classNames('content-wrapper', className)} style={style}>
      {children}
    </div>
  </Fragment>
)

export default ContentWrapper
