import React from 'react'
import ReactDOM from 'react-dom'
import momentTz from 'moment-timezone'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css/animate.css'
import './theme.less'

import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'

import Notifications from './components/Notifications'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT || 'https://0kagnm1u82.execute-api.us-east-1.amazonaws.com/production/graphql'
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = window.localStorage.getItem('token')

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'Time-Zone': momentTz.tz.guess(),
      Authorization: token
    }
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <App/>
    </Router>
    <Notifications/>
  </ApolloProvider>,
  document.getElementById('root')
)
