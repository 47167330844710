import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoSquare } from '@fortawesome/pro-regular-svg-icons'
import './index.less'

const NoData = ({
  contentComponent: ContentComponent,
  fullWidth,
  icon,
  secondaryTitle,
  small,
  title
}) => (
  <div
    className={classNames('no-data', {
      small: !!small,
      'full-width': !!fullWidth
    })}
  >
    <div className="icon-block">
      <FontAwesomeIcon icon={icon} className="icon" />
    </div>
    <div className="title-block">
      {ContentComponent ? <ContentComponent /> : (
        <Fragment>
          <div className="title">{title}</div>
          {secondaryTitle && (
            <div className="secondary-title">{secondaryTitle}</div>
          )}
        </Fragment>
      )}
    </div>
  </div>
)

NoData.propTypes = {
  fullWidth: PropTypes.bool,
  icon: PropTypes.object,
  secondaryTitle: PropTypes.string,
  small: PropTypes.bool,
  title: PropTypes.string
}

NoData.defaultProps = {
  fullWidth: false,
  icon: faInfoSquare,
  secondaryTitle: '',
  small: false,
  title: 'There is no data matching search criteria.'
}

export default NoData
