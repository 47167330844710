import React, { Fragment } from 'react'
import ContactForm from '../components/ContactForm'
import ContentWrapper from '../components/ContentWrapper'

const ContactFormView = () => (
  <Fragment>
    <ContentWrapper>
      <h1 className="display-4">Contact Us</h1>
      <ContactForm />
    </ContentWrapper>
  </Fragment>
)

export default ContactFormView
