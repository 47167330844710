import React from 'react'
import Reset from '../components/Reset'
import ContentWrapper from '../components/ContentWrapper'

const ResetView = () => (
  <ContentWrapper>
    <div className="row justify-content-md-center">
      <div className="col col-md-7 col-lg-6">
        <h1 className="display-4">Password Recovery</h1>
        <Reset />
      </div>
    </div>
  </ContentWrapper>
)

export default ResetView
