import React, { Fragment } from 'react'
import ContentWrapper from '../components/ContentWrapper'

const FAQView = () => (
  <Fragment>
    <ContentWrapper>
      <h1 className="display-4">Frequently Asked Questions (FAQ)</h1>
      <h2 className="mt-5 mb-4 text-primary">Hardware</h2>
      <h4>Praesent volutpat risus euismod est maximus tempus?</h4>
      <p>
        Aliquam ac est ac libero ullamcorper molestie. Aliquam erat volutpat.
        Proin sed massa sit amet turpis fringilla suscipit. Ut convallis non
        diam eget fringilla. Mauris dictum fermentum efficitur. Mauris tempor
        purus non nisi fermentum, non pellentesque orci lacinia.
      </p>
      <h4>Aliquam erat volutpat?</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam non
        interdum orci, sit amet gravida nunc. Proin in nulla elit. Interdum et
        malesuada fames ac ante ipsum primis in faucibus. Vestibulum malesuada
        quam non tortor finibus, at mattis quam semper.
      </p>
      <h4>Donec vestibulum blandit ornare?</h4>
      <p>
        Aliquam ac est ac libero ullamcorper molestie. Aliquam erat volutpat.
        Proin sed massa sit amet turpis fringilla suscipit. Ut convallis non
        diam eget fringilla. Mauris dictum fermentum efficitur. Mauris tempor
        purus non nisi fermentum, non pellentesque orci lacinia.
      </p>
      <h4>Cras quis orci vel mi condimentum feugiat?</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam non
        interdum orci, sit amet gravida nunc. Proin in nulla elit. Interdum et
        malesuada fames ac ante ipsum primis in faucibus.
      </p>
      <h4>Sed pharetra libero et felis finibus, ac commodo felis tempor?</h4>
      <p>
        Aliquam ac est ac libero ullamcorper molestie. Aliquam erat volutpat.
        Proin sed massa sit amet turpis fringilla suscipit. Ut convallis non
        diam eget fringilla. Mauris dictum fermentum efficitur. Mauris tempor
        purus non nisi fermentum, non pellentesque orci lacinia.
      </p>
      <h2 className="mt-5 mb-4 text-primary">Software</h2>
      <h4>Aliquam erat volutpat?</h4>
      <p>
        Aliquam ac est ac libero ullamcorper molestie. Aliquam erat volutpat.
        Proin sed massa sit amet turpis fringilla suscipit. Ut convallis non
        diam eget fringilla. Mauris dictum fermentum efficitur. Mauris tempor
        purus non nisi fermentum, non pellentesque orci lacinia.
      </p>
      <h4>Praesent volutpat risus euismod est maximus tempus?</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam non
        interdum orci, sit amet gravida nunc. Proin in nulla elit. Interdum et
        malesuada fames ac ante ipsum primis in faucibus. Vestibulum malesuada
        quam non tortor finibus, at mattis quam semper.
      </p>
      <h4>Donec vestibulum blandit ornare?</h4>
      <p>
        Aliquam ac est ac libero ullamcorper molestie. Aliquam erat volutpat.
        Proin sed massa sit amet turpis fringilla suscipit. Ut convallis non
        diam eget fringilla. Mauris dictum fermentum efficitur. Mauris tempor
        purus non nisi fermentum, non pellentesque orci lacinia.
      </p>
      <h4>Cras quis orci vel mi condimentum feugiat?</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam non
        interdum orci, sit amet gravida nunc. Proin in nulla elit. Interdum et
        malesuada fames ac ante ipsum primis in faucibus.
      </p>
      <h4>Sed pharetra libero et felis finibus, ac commodo felis tempor?</h4>
      <p>
        Aliquam ac est ac libero ullamcorper molestie. Aliquam erat volutpat.
        Proin sed massa sit amet turpis fringilla suscipit. Ut convallis non
        diam eget fringilla. Mauris dictum fermentum efficitur. Mauris tempor
        purus non nisi fermentum, non pellentesque orci lacinia.
      </p>
      <h2 className="mt-5 mb-4 text-primary">Payments</h2>
      <h4>Praesent volutpat risus euismod est maximus tempus?</h4>
      <p>
        Aliquam ac est ac libero ullamcorper molestie. Aliquam erat volutpat.
        Proin sed massa sit amet turpis fringilla suscipit. Ut convallis non
        diam eget fringilla. Mauris dictum fermentum efficitur. Mauris tempor
        purus non nisi fermentum, non pellentesque orci lacinia.
      </p>
      <h4>Aliquam erat volutpat?</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam non
        interdum orci, sit amet gravida nunc. Proin in nulla elit. Interdum et
        malesuada fames ac ante ipsum primis in faucibus. Vestibulum malesuada
        quam non tortor finibus, at mattis quam semper.
      </p>
      <h4>Donec vestibulum blandit ornare?</h4>
      <p>
        Aliquam ac est ac libero ullamcorper molestie. Aliquam erat volutpat.
        Proin sed massa sit amet turpis fringilla suscipit. Ut convallis non
        diam eget fringilla. Mauris dictum fermentum efficitur. Mauris tempor
        purus non nisi fermentum, non pellentesque orci lacinia.
      </p>
      <h4>Cras quis orci vel mi condimentum feugiat?</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam non
        interdum orci, sit amet gravida nunc. Proin in nulla elit. Interdum et
        malesuada fames ac ante ipsum primis in faucibus.
      </p>
      <h4>Sed pharetra libero et felis finibus, ac commodo felis tempor?</h4>
      <p>
        Aliquam ac est ac libero ullamcorper molestie. Aliquam erat volutpat.
        Proin sed massa sit amet turpis fringilla suscipit. Ut convallis non
        diam eget fringilla. Mauris dictum fermentum efficitur. Mauris tempor
        purus non nisi fermentum, non pellentesque orci lacinia.
      </p>
      <h2 className="mt-5 mb-4 text-primary">General</h2>
      <h4>Aliquam erat volutpat?</h4>
      <p>
        Aliquam ac est ac libero ullamcorper molestie. Aliquam erat volutpat.
        Proin sed massa sit amet turpis fringilla suscipit. Ut convallis non
        diam eget fringilla. Mauris dictum fermentum efficitur. Mauris tempor
        purus non nisi fermentum, non pellentesque orci lacinia.
      </p>
      <h4>Praesent volutpat risus euismod est maximus tempus?</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam non
        interdum orci, sit amet gravida nunc. Proin in nulla elit. Interdum et
        malesuada fames ac ante ipsum primis in faucibus. Vestibulum malesuada
        quam non tortor finibus, at mattis quam semper.
      </p>
      <h4>Donec vestibulum blandit ornare?</h4>
      <p>
        Aliquam ac est ac libero ullamcorper molestie. Aliquam erat volutpat.
        Proin sed massa sit amet turpis fringilla suscipit. Ut convallis non
        diam eget fringilla. Mauris dictum fermentum efficitur. Mauris tempor
        purus non nisi fermentum, non pellentesque orci lacinia.
      </p>
      <h4>Cras quis orci vel mi condimentum feugiat?</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam non
        interdum orci, sit amet gravida nunc. Proin in nulla elit. Interdum et
        malesuada fames ac ante ipsum primis in faucibus.
      </p>
      <h4>Sed pharetra libero et felis finibus, ac commodo felis tempor?</h4>
      <p>
        Aliquam ac est ac libero ullamcorper molestie. Aliquam erat volutpat.
        Proin sed massa sit amet turpis fringilla suscipit. Ut convallis non
        diam eget fringilla. Mauris dictum fermentum efficitur. Mauris tempor
        purus non nisi fermentum, non pellentesque orci lacinia.
      </p>
    </ContentWrapper>
  </Fragment>
)

export default FAQView
