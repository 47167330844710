import React from 'react'
import './index.less'

export default () => (
  <div className="loader">
    <div className="dot1" />
    <div className="dot2" />
    <div className="dot3" />
  </div>
)
