import React, { Component } from 'react'
import logo from '../Header/logo.png'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import UserContext from '../../lib/user-context'
import URLS, { userMenuItems } from '../../config/urls'

class Header extends Component {
  state = {
    open: false
  }

  onToggleMenu = () => this.setState({ open: !this.state.open })

  render () {
    const { location: { pathname } } = this.props
    const checkIsActive = url => !!pathname.match(url)
    return (
      <div className="bg-dark">
        <nav className="navbar navbar-expand-md navbar-dark bg-dark default-wrapper">
          <Link className="navbar-brand" to={URLS.DASHBOARD}>
            <img height="48" src={logo} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExampleDefault"
            aria-controls="navbarsExampleDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this.onToggleMenu}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`collapse navbar-collapse ${this.state.open && 'show'}`} id="navbarsExampleDefault">
            <ul className="navbar-nav mr-auto">
              {userMenuItems.map(({ name, url }) => (
                <li
                  className={classNames('nav-item', {
                    active: checkIsActive(url)
                  })}
                  key={name}
                >
                  <Link className="nav-link" to={url}>
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
            <UserContext.Consumer>
              {({ user, signOut }) => (
                <Link
                  to="/sign-out"
                  onClick={e => {
                    e.preventDefault()
                    signOut()
                  }}
                  data-cy="signOutButton"
                  className="btn btn-dark my-sm-0"
                >
                Sign Out
                </Link>
              )}
            </UserContext.Consumer>
          </div>
        </nav>
      </div>
    )
  }
}

export default withRouter(Header)
