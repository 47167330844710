import React, { Fragment } from 'react'
import ContentWrapper from '../ContentWrapper'
import SeoContent from '../SeoContent'
import URLS from '../../config/urls'
import { Link } from 'react-router-dom'
import logo from './logo.png'
import insPicOne from './insPicOne.jpg'
import insPicTwo from './insPicTwo.jpg'
import insPicThree from './insPicThree.jpg'
import './index.less'

const APP_URL = process.env.REACT_APP_BASE_URL
const seo = {
  title: 'Insurance Based GPS Asset Tracking Solution | Insurance Providers | EcoTrack',
  description:
    'Manage risk and confirm compliance with an asset tracking solution that allows insurance providers to manage their risk simply and effectively. Utilize a single dashboard to manage geo location details of all insured equipment and assets. Understand potential exposure by gaining insight into geo-location clusters that might be at risk of loss due to extreme weather, theft, or major plant accidents.',
  url: `${APP_URL}${URLS.INSURANCE}/`,
  image: APP_URL + logo
}

const Insurance = () => (
  <Fragment>
    <ContentWrapper>
      <SeoContent {...seo} />
      <div className="bg-insurance">
        <h1 className="display-3 text-light text-center h1-landing">Insurance</h1>
      </div>
      <hr className="line-center" />
      <h2 className="h2-landing text-secondary">Insurance Based GPS Asset Tracking Solution for Insurance Providers</h2>
      <p className="p-landing text-center mt-4">
      Insurance is absolutely vital in the transport and construction industries and there are so many variables that can dictate a specific policy.
      So much could go wrong between destinations or in the space of time between the first and last day of a construction job. We know that too.
      Understanding where you equipment will be used can be a major piece of your policy puzzle. EcoTrack provides our customers an easy, low cost way to do that.
      </p>
      <div className="insurance-one">
        <div className="ins-policy">
          <h3 className="h3-landing px-5 text-dark">Insurance Policy</h3>
          <p className="p-landing">
          By using the EcoTrack dashboard, you’re able to track tasks & reminders for a specific policy, while also retrieving the locations of the assets that you’ve ensured.
          The data can then be used in reassessing policies as exposure to risk may shift over the course of the term. This allows more tailored insurance policies for customers and assists in creating a partnership with the insurer.
          Customers can now play an active role in their insurance policy by limiting the risk they expose their equipment or vehicles to.
          </p>
        </div>
        <div className="ins-pic-one">
          <img className="img-fluid rounded" src={insPicOne} alt=""/>
        </div>
      </div>
      <div className="insurance-two">
        <div className="ins-pic-two">
          <img className="img-fluid rounded" src={insPicTwo} alt=""/>
        </div>
        <div className="ins-stick">
          <h3 className="h3-landing px-5 text-dark">Stick and Track - No headache simple setup.</h3>
          <p className="p-landing">By using two cell and two way batteries in our hardware packs, customers receive an EcoTracker with a battery that will provide up to 10 years of service in almost every condition you could put it through.
        We believe adaptability is one of the cornerstones of a successful business and that’s why we provide our customers with a product that can operate independent of a vehicle’s power system.
        This way the EcoTrack will be providing data no matter what happens to a specific asset or it’s electrical system. When you track a small group of equipment or an entire fleet of trucks, we have you and your logistics company covered.
          </p>
        </div>
      </div>
      <h3 className="h3-landing px-5 text-dark">Manage Liability - Simple Install - Manage Risk</h3>
      <p className="p-landing">When insuring equipment and vehicles, there hasn’t always been an easy way to make sure the company or individual that’s insured is compliant with your specific location covenants.
      EcoTrack can easily verify that the equipment you financed has stayed within your required geographic limits. With EcoTrack you can easily create a geofence as wide or as narrow as you require. As soon as your asset has crossed the line,
      you’re immediately alerted and can quickly determine your next course of action.</p>
      <div className="middle-page-btn">
        <Link to={URLS.SIGN_UP} className="btn btn-primary btn-lg p-3">Get started with EcoTrack</Link>
      </div>
      <div className="insurance-three">
        <div className="ins-pic-three">
          <img className="img-fluid rounded" src={insPicThree} alt=""/>
        </div>
        <div className="ins-tracker">
          <h3 className="h3-landing px-5 text-dark"> EcoTrack GPS Fleet Tracker</h3>
          <p className="p-landing">
          One important part of using an EcoTrack GPS Fleet Tracker is providing one more piece of safety equipment for our customers and their employees. Incase of emergency, the operator of a specific piece of equipment or vehicle can be easily
          located with an increased ability to provide all necessary assistance quicker and more efficiently than ever. Even if a vehicle or trailer is stolen, our Geofencing features will alert all necessary parties immediately, allowing for a quick response.
          </p>
        </div>
      </div>
      <div className="insurance-four">
        <div className="ins-goals">
          <h3 className="h3-landing text-dark">Our Goals</h3>
          <p className="p-landing">
          Our goal with EcoTrack to make sure that as an insurer, you’re able to provide the most comprehensive policies possible. That’s why our customers use EcoTrack to access equipment management software, insurance asset tracking and financed equipment tracking.
          At base level, EcoTrack is a tool that was designed to help insurers and the people they insure. So browse our latest technology and find a GPS tracking solution that works best for you.
          </p>
        </div>
        <div className="ins-price-div">
          <h2 className="h2-button">Get started with EcoTrack</h2>
          <Link to={URLS.PRICING} className="btn btn-light price-button">Check our prices</Link>
        </div>
      </div>

    </ContentWrapper>
  </Fragment>
)

export default Insurance
