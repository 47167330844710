import React, { Component } from 'react'
import TrackingStats from '../TrackingStats'
import UtilizationStats from '../UtilizationStats'

export default class Stats extends Component {
  render () {
    return (
      <div style={{ display: 'grid', gridGap: '20px', gridTemplateColumns: '2fr 1fr' }}>
        <TrackingStats />
        <UtilizationStats />
      </div>
    )
  }
}
