import React, { Component, Fragment } from 'react'
import isFunction from 'lodash/isFunction'
import classNames from 'classnames'
import './index.less'

let toggleSidebar

class SideBar extends Component {
  constructor (props) {
    super(props)
    toggleSidebar = this.onToggleSideBar
  }

  state = {
    open: false
  }

  sideBarRef = React.createRef()

  componentWillUnmount () {
    window.removeEventListener('click', this.onHandleSidebar, true)
    window.removeEventListener('keydown', this.onEscapeKeyClose, false)
  }

  onAddEventListener = () => {
    window.addEventListener('click', this.onHandleSidebar, true)
    window.addEventListener('keydown', this.onEscapeKeyClose, false)
  }

  onEscapeKeyClose = ({ keyCode }) => {
    if (keyCode === 27) {
      this.setState({ open: false })
    }
  }

  onHandleSidebar = ({ target }) => {
    const { open } = this.state
    const { onCloseCallback } = this.props
    if (open && this.sideBarRef.current.contains(target)) {
      return
    }

    if (!open && this.sideBarRef.current.contains(target)) {
      this.setState({ open: true })
    } else {
      this.setState({ open: false })
      if (onCloseCallback && isFunction(onCloseCallback)) {
        onCloseCallback()
      }
    }
  }

  onToggleSideBar = (forceClose) => {
    if (forceClose) {
      return this.onCloseSideBar()
    }
    this.setState(({ open }) => {
      return {
        open: !open
      }
    })
    this.onAddEventListener()
  }

  onCloseSideBar = e => {
    e && e.stopPropagation()
    this.setState({ open: false })
  }

  force = () => {
    const { open } = this.state
    if (window.innerWidth < 600) {
      if (open) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }
    }
  }

  render () {
    const { open } = this.state
    const { children, title, controlsComponent } = this.props
    this.force()
    return (
      <Fragment>
        <div
          className={classNames('side-bar shadow', { open: !!open, controls: !!controlsComponent })}
          ref={this.sideBarRef}
        >
          <div className="card-header p-3">
            <h5 className="d-inline-block card-title mb-0" data-cy="sidebarTitle">
              {title}
            </h5>
            <div className="close-control" onClick={this.onCloseSideBar}>
              <small className="text-muted">esc</small>
              <button type="button" className="close" aria-label="Close" data-cy="closeButtonTimes">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div className="p-3 h-100 overflow-scroll">{children}</div>
        </div>
        {open && (
          <div className="backdrop" style={{ height: document.getElementById('root').clientHeight }} />
        )}
      </Fragment>
    )
  }
}

export { toggleSidebar }
export default SideBar
