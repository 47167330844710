import React, { Fragment } from 'react'
import { Checkbox } from './index'

const SelectAll = ({ onSelectAll, checked }) => (
  <div
    onClick={onSelectAll}
    className="form-inline d-flex justify-content-between text-primary pointer"
  >
    <strong className="h6 mb-0">Select All</strong>{' '}
    <Checkbox onChange={onSelectAll} checked={checked} />
  </div>
)

const ClearAll = ({ onClearAll }) => (
  <small
    onClick={onClearAll}
    className="form-inline d-flex justify-content-between text-primary pointer clear-all"
  >
    <strong className="h6 mb-0">Clear All</strong>{' '}
  </small>
)

export default ({ onSelectAll, onClearAll = () => {}, checked }) => (
  <Fragment>
    <div className="dropdown-header">
      {onSelectAll && <SelectAll onSelectAll={onSelectAll} checked={checked} />}
      {onClearAll && <ClearAll onClearAll={onClearAll}/>}
    </div>
    <hr className="mb-1 mt-0" />
  </Fragment>
)
