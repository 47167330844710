import React, { Component, Fragment } from 'react'
import startCase from 'lodash/startCase'
import pick from 'lodash/pick'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import classNames from 'classnames'
import { faTrashAlt, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { Button, DropDown, DropDownHeader } from '../index'
import { notify } from '../Notifications'
import assetsQuery from '../AssetsWithQuery/assets.graphql'
import TagTypesDropDownOptions from '../TagTypesDropDownOptions'
import TagTypesWithTags from '../TagTypesWithTags'
import { TITLE_DELETE } from '../../config/constants'
import './index.less'

const formStructure = ['name', 'externalNo', 'referenceNo', 'make', 'model', 'modemNumber']
const busyTypes = { remove: 'remove', add: 'add', update: 'update' }

class AssetsSidebarContent extends Component {
  constructor (props) {
    super(props)
    const state = pick(props.asset, formStructure)
    this.state = {
      showDeleteQuestion: false,
      selected: (get(props, 'asset.tags') || []),
      modemNumber: get(props, 'asset.device.modemNumber'),
      ...state
    }
  }

  static getDerivedStateFromProps ({ isOpened }, nextState) {
    if (!isOpened) {
      const emptyState = {}
      formStructure.forEach(stateKey => { emptyState[stateKey] = null })
      return { ...nextState, ...emptyState }
    }
    return nextState
  }

  onAddNewAsset = async e => {
    e.preventDefault()
    const { busy, name, externalNo, referenceNo, make, model, modemNumber, selected } = this.state
    const { onClose, onClearSideBar } = this.props
    if (busy !== busyTypes.add) {
      this.setState({ busy: busyTypes.add })
      const { createMutation } = this.props
      const variables = {
        name,
        externalNo,
        referenceNo,
        make,
        model,
        modemNumber,
        tags: selected.map(({ id }) => id)
      }
      const {
        data: {
          createAsset: {
            result: { success, reason }
          }
        }
      } = await createMutation({
        variables,
        refetchQueries: [{ query: assetsQuery }]
      })
      if (success) {
        this.setState({ busy: false, error: '' })
        if (onClose && isFunction(onClose)) onClose()
        if (onClearSideBar && isFunction(onClearSideBar)) onClearSideBar()
        notify('Asset created!', 'success')
      } else {
        notify('Asset create failed!', 'danger')
        this.setState({ busy: false, error: reason.message })
      }
    }
  }

  onUpdateAsset = async e => {
    e.preventDefault()
    const { busy, name, externalNo, referenceNo, make, model, modemNumber, selected } = this.state
    const { onClose, onClearSideBar } = this.props
    if (busy !== busyTypes.update) {
      this.setState({ busy: busyTypes.update })
      const { updateMutation, id } = this.props
      const variables = {
        id,
        name,
        externalNo,
        referenceNo,
        make,
        model,
        modemNumber,
        tags: selected.map(tag => tag.id)
      }
      const {
        data: {
          updateAsset: {
            result: { success, reason }
          }
        }
      } = await updateMutation({
        variables,
        refetchQueries: [{ query: assetsQuery, variables: {} }]
      })
      if (success) {
        this.setState({ busy: false, error: '' })
        if (onClose && isFunction(onClose)) onClose()
        if (onClearSideBar && isFunction(onClearSideBar)) onClearSideBar()
        notify('Asset updated!', 'success')
      } else {
        notify('Asset update failed!', 'danger')
        this.setState({ busy: false, error: reason.message })
      }
    }
  }

  onRemoveAsset = async e => {
    e.preventDefault()
    const { removeMutation, id, onClose, onClearSideBar } = this.props
    const { busy } = this.state
    if (busy !== busyTypes.remove) {
      this.setState({ busy: busyTypes.remove })
      const {
        data: {
          removeAsset: {
            result: { success, reason }
          }
        }
      } = await removeMutation({
        variables: { id },
        refetchQueries: [{ query: assetsQuery, variables: {} }]
      })
      if (success) {
        this.setState({ busy: false, error: '' })
        if (onClose && isFunction(onClose)) onClose()
        if (onClearSideBar && isFunction(onClearSideBar)) onClearSideBar()
        notify('Asset removed!', 'success')
      } else {
        notify('Asset remove failed!', 'danger')
        this.setState({ busy: false, error: reason.message })
      }
    }
  }

  onChange = ({ target: { value, dataset } = {} } = {}) => {
    this.setState({ [dataset.name]: value })
  }

  onSelectAll = allTagsIds => {
    const { selected } = this.state
    this.setState({
      selected: allTagsIds.length === selected.length ? [] : allTagsIds
    })
  }

  onSelect = (id, name) => {
    const { selected } = this.state
    const isInSelected = selected.find(({ id: selectedId }) => selectedId === id)
    if (isInSelected) {
      this.setState({
        selected: selected.filter(({ id: selectedId }) => selectedId !== id)
      })
    } else {
      this.setState({ selected: [...selected, { id, name }] })
    }
  }

  mapSelectedValuesToNames = () => {
    const { selected } = this.state
    if (!selected.length) return null
    if (selected.length > 2) {
      return `Tags (${selected.length})`
    }
    return selected.map(({ name }) => name).join(', ')
  }

  onSelectAllInTagType = (allTagsIds, tags) => {
    const { selected } = this.state
    const selectedIds = selected.map(({ id }) => id)
    const allTagIdInSelectedIndex = allTagsIds
      .map(tagId => selectedIds.includes(tagId))
      .filter(Boolean).length

    if (allTagIdInSelectedIndex === allTagsIds.length) {
      const filteredSelectedValues = selected.filter(
        ({ id: selectedId }) => !allTagsIds.includes(selectedId)
      )
      this.setState({ selected: filteredSelectedValues })
    } else {
      const allTags = allTagsIds.map(tagId => tags.find(({ id }) => id === tagId))
      this.setState({ selected: uniqBy([...selected, ...allTags], 'id') })
    }
  }

  onToggleDeleteQuestion = () => this.setState({ showDeleteQuestion: !this.state.showDeleteQuestion });

  render () {
    const { id, onClose } = this.props
    const { selected, name, busy, showDeleteQuestion } = this.state

    return (
      <div className="assets-sidebar-content">
        <div className="content-form">
          <form>
            {formStructure.map(field => {
              const formattedLabel = startCase(field)
              const value = this.state[field] || this.props[field] || ''
              return (
                <div className="form-group mb-1" key={field}>
                  <label>
                    {formattedLabel} {field === 'name' && '*'}
                  </label>
                  <input
                    className="form-control"
                    data-name={field}
                    value={value || ''}
                    placeholder={formattedLabel}
                    onChange={this.onChange}
                  />
                  {field === 'name' && (
                    <small id="emailHelp" className="form-text text-muted">
                      Required
                    </small>
                  )}
                </div>
              )
            })}
            <div className="form-group">
              <label>Tags</label>
              <TagTypesWithTags>
                {({ tagTypes, allTagsIds }) => (
                  <DropDown
                    dropDownCustomClass="tag-types-drop-down assets-drop-down"
                    openComponent={() => (
                      <input
                        className="form-control tags-dropdown-input"
                        data-name="tags"
                        value={this.mapSelectedValuesToNames() || ''}
                        placeholder="Select tags"
                        onChange={this.onChange}
                        readOnly
                      />
                    )}
                  >
                    <DropDownHeader
                      onSelectAll={() => this.onSelectAll(allTagsIds)}
                      checked={allTagsIds.length === selected.length}
                    />
                    {tagTypes.map((tagType, key) => (
                      <TagTypesDropDownOptions
                        key={tagType.id}
                        onSelect={this.onSelect}
                        onSelectAllInTagType={this.onSelectAllInTagType}
                        selected={selected}
                        forceOpen={key === 0}
                        {...tagType}
                      />
                    ))}
                  </DropDown>
                )}
              </TagTypesWithTags>
            </div>
          </form>
        </div>
        <br/>
        <div className={classNames('content-controls', { remove: !!id && !showDeleteQuestion })}>
          {showDeleteQuestion ? (
            <Fragment>
              <strong>Remove asset?</strong>
              <br/>
              <Button variant="light" label="Cancel" onClick={this.onToggleDeleteQuestion} />
              <Button
                variant="danger"
                icon={busy && busy === busyTypes.remove && faSpinnerThird}
                iconSpin={busy && busy === busyTypes.remove}
                label=" Remove"
                onClick={this.onRemoveAsset}
              />
            </Fragment>
          ) : (
            <Fragment>
              <Button variant="light" label="Close" onClick={onClose} />
              <Button
                disabled={!name}
                icon={busy && busy !== busyTypes.remove && faSpinnerThird}
                iconSpin={busy && busy !== busyTypes.remove}
                label={id ? ' Update' : ' Add'}
                onClick={id ? this.onUpdateAsset : this.onAddNewAsset}
              />
              {id && (
                <Button
                  variant="danger"
                  icon={faTrashAlt}
                  onClick={this.onToggleDeleteQuestion}
                  title={TITLE_DELETE}
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}

export default AssetsSidebarContent
