import React, { Fragment } from 'react'
import ContentWrapper from '../components/ContentWrapper'

const AboutUsView = () => (
  <Fragment>
    <ContentWrapper>
      <h1 className="display-4">About Us</h1>
      <p>
      EcoTrack was founded in 2019 in Austin, Texas by people that managed heavy equipment & transportation assets every day.
      They found ourselves constantly searching for a more efficient and cost-effective way to track their equipment,
      which was often spread out all over the United States.
      </p>
      <p>
      They needed a solution and decided to take action. Since their founding, EcoTrack’s main focus has been to provide simple,
      affordable tracking systems to owners of heavy equipment, commercial vehicle fleets, trailers and shipping containers in an effort to help them
      do two things very well: Mitigate risk by tracking equipment and understanding utilization & dormancy.
      In this quest they created a rugged product that could not only support their own goals,
      but help countless others manage and track their own equipment to reach theirs.
      </p>
    </ContentWrapper>
  </Fragment>
)
export default AboutUsView
