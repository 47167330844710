import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './index.less'

const Checkbox = ({ checked, onChange, ...rest }) => (
  <input
    className={classNames('checkbox')}
    type="checkbox"
    checked={checked}
    onChange={onChange}
    data-cy="checkbox"
    {...rest}
  />
)

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func
}

Checkbox.defaultProps = {
  onChange: () => {}
}

export default Checkbox
