import Input from '../Input'
import React, { Fragment } from 'react'
import './form.less'
import { Link } from 'react-router-dom'

const SignInForm = ({ errors, onSubmit, onChange, password, email, onKeyPress }) => (
  <Fragment>
    <form className="Form" onSubmit={onSubmit}>
      <Input type="email" value={email} onChange={onChange('email')} label="Email Address" />
      <Input error={errors.password} type="password" onKeyPress={onKeyPress} value={password} onChange={onChange('password')} label="Password" />
      <p className="Form__Buttons">
        <button className="mt-2 mr-3 btn btn-secondary disabled">Sign In with Google</button>
        <button onClick={onSubmit} className="mt-2 btn btn-primary">Sign In</button>
      </p>
    </form>
    { errors.password ? <p className="text-muted">
      You don`t remember your password?  <Link to="/reset-password"> Reset </Link> your password here.
    </p> : <p className="text-muted">
      You don`t have an account yet? <Link to="/sign-up"> Sign Up </Link> instead.
    </p> }
  </Fragment>
)

export default SignInForm
