import React from 'react'
import { Query } from 'react-apollo'
import tagsQuery from '../Tags/tags.graphql'
import TagsEditDialog from './TagsEditDialog'

export default ({ tagType }) => (
  <Query query={tagsQuery} variables={{ tagType }}>
    {props => <TagsEditDialog tagType={tagType}{...props} />}
  </Query>
)
