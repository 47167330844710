import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import './index.less'

const Badge = ({ label, icon, onClick, type, size, noMargin, hoverable, showCloseIcon, className }) => (
  <span
    className={classNames(`badge badge-pill badge-${type}`, className, {
      [size]: !!size,
      'no-margin': !!noMargin,
      'hoverable': !!hoverable
    })}
    onClick={onClick}
    data-cy="tagsBadge"
  >
    {icon && <FontAwesomeIcon icon={icon} className="icon" />}
    {label}
    {showCloseIcon && (<span className="close-icon">&nbsp;&times;</span>)}
  </span>
)

Badge.defaultProps = {
  type: 'primary',
  label: 'Tag Name',
  size: '',
  noMargin: false,
  hoverable: false,
  onClick: () => {}
}

export default Badge
