import React, { Fragment, Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import classNames from 'classnames'
import logo from './logo.png'
import UserContext from '../../lib/user-context'
import { URLS, solutionsMenuItems, productOverviewMenuItems } from '../../config/urls'
import { DropDown } from '../index'
import './index.less'

class Header extends Component {
  state = {
    open: false,
    dropdownOpenSol: false,
    dropdownOpenFeat: false
  }

  onToggleMenu = () => this.setState({ open: !this.state.open })

  isMenuItemActive = url => {
    const { location: { pathname } } = this.props
    return !!url && pathname.match(url)
  }

  renderOpenComponent = buttonTitle => (
    <button
      className="btn btn-secondary dropdown-toggle"
    >
      {buttonTitle}<span className="sr-only">(current)</span>
    </button>
  )

  renderDropDown = ({ title, menuItems }) => (
    <DropDown
      className="dropdown nav-link"
      openComponent={() => this.renderOpenComponent(title)}
      closeOnClick
    >
      {menuItems.map(({ name, url }) => (
        <Link
          key={name}
          className={classNames('dropdown-item', { active: this.isMenuItemActive(url) })}
          to={url}
        >
          {name}
        </Link>
      ))}
    </DropDown>
  )

  render () {
    const { open } = this.state
    return (
      <div className="bg-dark">
        <nav className="container navbar navbar-expand-md navbar-dark bg-dark">
          <Link className="navbar-brand" to={URLS.HOME}>
            <img height="48" src={logo} alt="Ecotrack logo"/>
          </Link>
          <button
            onClick={this.onToggleMenu}
            className="navbar-toggler"
            type="button"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className={classNames('collapse navbar-collapse', { show: !!open })} id="navbarsExampleDefault" data-cy="headerNavbar">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                {this.renderDropDown({ title: 'Solutions', menuItems: solutionsMenuItems })}
              </li>
              <li className="nav-item">
                {this.renderDropDown({ title: 'Product Overview', menuItems: productOverviewMenuItems })}
              </li>
              <li className="nav-item">
                <div className="dropdown nav-link">
                  <button className="btn btn-secondary dropdown-toggle disabled" href="#why-ecotrack">
                  Why Ecotrack?
                  </button>
                </div>
              </li>
              <li className="nav-item">
                <div className="dropdown nav-link">
                  <Link to={URLS.PRICING} className="btn btn-secondary" href="#buy-now">
                    Pricing
                  </Link>
                </div>
              </li>
            </ul>
            <UserContext.Consumer>
              { ({ user, signOut }) => (
                user ? (
                  <Fragment>
                    <Link className="btn btn-outline-light" to={URLS.DASHBOARD}> &laquo; back to App </Link>
                    <Link to="/sign-out" onClick={e => {
                      e.preventDefault()
                      signOut()
                    }} className="btn btn-dark my-2 my-sm-0" data-cy="signOutButton">Sign Out</Link>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Link to={URLS.SIGN_UP} id="signUpButton" data-cy="signUpButton" className="btn btn-outline-light">Sign Up</Link>
                    <Link to={URLS.SIGN_IN} id="signInButton" data-cy="signInButton" className="btn btn-dark">Sign In</Link>
                  </Fragment>
                )
              )}
            </UserContext.Consumer>
          </div>
        </nav>
      </div>
    )
  }
}
export default withRouter(Header)
