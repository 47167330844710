import React, { Fragment } from 'react'
import ContentWrapper from '../components/ContentWrapper'

const SupportView = () => (
  <Fragment>
    <ContentWrapper className="d-grid">
      <h1 className="display-4">Support</h1>
      <p>
      When purchasing an EcoTrack tracking device for your vehicle, equipment, trailer or storage container, you’re making a choice to invest in your own success.
      We believe that supporting that success is where we can continue to help.
      It’s a tough world out there and it’s our mission to make sure we can provide you with what you need to keep moving forward.
      </p>
      <p>
      By installing and using the EcoTrack app, our learning portal will provide you with detailed instructions
      and usage guides to help you and your employees squeeze every ounce of functionality and utility out of EcoTrack.
      </p>
      <h2 className="text-primary">Implementation</h2>
      <p>
      Installing 20 units or more? Ecotrack will coordinate and installs tracking devices on your fleet with our preferred third party installation specialists.
      Our team is trained and equipped with best practice installation approaches and OSHA approved safety gear. For more information about the services and pricing,
      please reach out to our team here. We’ll provide answers as soon as possible. You support us, it’s only right that we support you.
      </p>
    </ContentWrapper>
  </Fragment>
)
export default SupportView
