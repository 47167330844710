import React, { Fragment } from 'react'
import ContentWrapper from '../ContentWrapper'
import SeoContent from '../SeoContent'
import URLS from '../../config/urls'
import { Link } from 'react-router-dom'
import logo from './logo.png'
import constPicOne from './constPicOne.jpeg'
import constPicTwo from './constPicTwo.jpeg'
import constPicThree from './constPicThree.jpeg'
import './index.less'

const APP_URL = process.env.REACT_APP_BASE_URL
const seo = {
  title: 'Construction Equipment GPS Tracking | Equipment Tracking | EcoTrack',
  description:
    'Stick & Track for Heavy Equipment Tracking - EcoTrack devices have a 10 Year Life with rates as low as $3 per month. With EcoTrack, no matter how far apart your job sites are, you’ll always know where your trucks and equipment are. You track your progress; we’ll track your equipment.',
  url: `${APP_URL}${URLS.CONSTRUCTION}/`,
  image: APP_URL + logo
}

const Construction = () => (
  <Fragment>
    <ContentWrapper>
      <SeoContent {...seo} />
      <div className="bg-const">
        <h1 className="display-3 text-light text-center h1-landing">Construction</h1>
      </div>
      <hr className="line-center" />
      <h2 className="h2-landing text-secondary">Heavy Equipment Tracking for Construction</h2>
      <p className="p-landing text-center mt-4">
      Construction is hard work. And nothing works harder than the machines and equipment we employ day in and day out.
      In today’s modern world, people and especially their equipment, are spread out more than ever.
      Whether you lease your construction equipment to others or have your own personal machines that you send to each job site,
      you need to keep track of what’s yours. With EcoTrack, it’s never been easier or more cost effective.
      </p>
      <div className="construction-one">
        <div className="const-visible">
          <h3 className="h3-landing px-5 text-dark">Easy Install - Low Cost - Machine Tracking</h3>
          <p className="p-landing">
        One of the biggest advantages of employing EcoTrack’s GPS equipment management systems is the visibility it gives
        construction company of their heavy equipment. Not only will you be able to see where each piece of equipment is currently being used,
        you can also check to see which pieces of equipment have been lying dormant or unused and could be better utilized on another work site.
        If you lease fleets of construction equipment, understanding where each piece is working and for how long helps you schedule future rentals
        and allows deeper planning. If you have equipment, use EcoTrack to monitor it. Using our affordable GPS trackers increases revenue and improves
        utilization without breaking your budget.
          </p>
        </div>
        <div className="const-pic-one">
          <img className="img-fluid rounded" src={constPicOne} alt=""/>
        </div>
      </div>
      <div className="const-protection">
        <h3 className="h3-landing text-dark">Protection</h3>
        <p className="p-landing">
      Let’s face it, sometimes our equipment has a way of “leaving” the work site without our authorization or consent.
      By using EcoTrack for your equipment asset tracking, you’re protecting your investment from potential harm. If a piece of equipment is stolen,
      our Geofencing features will alert all necessary parties immediately, allowing for a quicker response. In case of emergency,
      your equipment and its operator can also be easily located with the construction fleet asset tracking of EcoTrack.
      This in turn helps improve your ability to provide any necessary assistance quicker and more effectively.
      We wanted to provide a product to our customers and their employees that not only operated as GPS tracking for their equipment,
      but another layer of safety on the job site. So that’s what we did.
        </p>
      </div>
      <div className="construction-two">
        <div className="const-pic-two">
          <img className="img-fluid rounded" src={constPicTwo} alt=""/>
        </div>
        <div className="const-communication">
          <h3 className="h3-landing text-dark">Communication</h3>
          <p className="p-landing">
      In construction, teamwork is paramount, but communication is the nail that holds teams and companies together.
      By using the EcoTrack line of equipment tracking solutions, you have the ability to share data related to specific
      jobs and pieces of equipment with the teams and subcontractors that depend on it most. Within the EcoTrack app,
      use the dashboard to track tasks and reminders and create groupings and equipment tag so your inventory data can be shared
      based on who specifically needs visibility.
          </p>
        </div>
      </div>
      <h3 className="h3-landing text-dark">Stick and Track – No headaches simple set up.</h3>
      <p className="p-landing">
      By using two cell and two way batteries in our hardware packs, customers receive an EcoTracker
      with a battery that will provide up to 10 years of service in almost any condition. We believe adaptability is one of the cornerstones of a successful
      business and that’s why we provide our customers with a product that can operate independent of a vehicles power system. This way the EcoTrack
      will be providing data no matter what happens to the equipment or its electrical system. Whether you track one piece of equipment or an entire fleet,
      we have you and your construction company covered.
      </p>
      <div className="construction-three">
        <div className="const-pic-three">
          <img className="img-fluid rounded" src={constPicThree} alt=""/>
        </div>
        <div className="const-solution">
          <h3 className="h3-landing text-dark">Tracking solution</h3>
          <p className="p-landing">
      Our goal with EcoTrack is to decrease dormancy rates and increase utilization for every one of our customers across every asset class.
      That’s why our product enables companies to track all equipment types including: trailer gps tracking, commercial vehicle tracking,
      general vehicle GPS tracking and equipment tracking. At base level, EcoTrack is a tool that was designed to help construction companies and their employees.
      So browse our newest technology and fine a GPS tracking solution that works best for you.
          </p>
          <div className="bottom-page-btn">
            <Link to={URLS.SIGN_UP} className="btn btn-primary btn-lg p-3">Get started with EcoTrack</Link>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </Fragment>
)

export default Construction
