import React from 'react'
import { faEllipsisV, faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { DropDown } from '../index'
import './index.less'

const AssetsTableRow = ({
  name,
  lastUpdated,
  make,
  model,
  externalNo,
  geolocation,
  tags,
  status,
  deviceId,
  onOpenSideBar,
  onNameClick
}) => {
  const clippedTags = tags.splice(0, 2)
  return (
    <div className="assets-table shadow-sm">
      <div className={deviceId ? 'active name' : 'disabled'} onClick={status ? onNameClick : () => {}}>
        <div className={classNames('asset-name', { [status]: !!status })}>{name}</div>
        <span className="updated-at">{lastUpdated}</span>
      </div>
      <div className="make">
        <div className="make-label">Make</div>
        {make || '-'}
      </div>
      <div className="model">
        <div className="model-label">Model</div>
        {model || '-'}
      </div>
      <div className="external-no">
        <div className="external-no-label">External No</div>
        {externalNo || '-'}
      </div>
      <div className="geolocation"><a href={`https://www.google.com/maps/search/?api=1&query=${geolocation}`}>{geolocation || ''}</a></div>
      <div className="device-id">
        <div className="device-id-label">Device ID</div>
        {deviceId || '-'}
      </div>
      <div className={classNames('status', { [status]: !!status })}>
        <span className="status-dot" />
        <div className="status-name">{status || 'Pending'}</div>
      </div>
      <div className="tags">
        {clippedTags.map(({ name: tagName, id: tagId }) => (
          <span
            key={tagId}
            className={classNames('badge', {
              'badge-primary': status === 'active',
              'badge-secondary': status !== 'active'
            })}
          >
            {tagName}
          </span>
        ))}
        {tags.length > 2 && (
          <DropDown
            dropDownCustomClass="assets-table-drop-down shadow"
            openComponent={() => (
              <span className={classNames('tag-dropdown', { [status]: !!status })}>
                <span
                  className={classNames('badge', {
                    'badge-primary': status === 'active',
                    'badge-secondary': status !== 'active'
                  })}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </span>
            )}
          >
            {tags.map(({ name: tagName }) => (
              <span key={tagName} className="tag-name-drop-down">
                {tagName}
              </span>
            ))}
          </DropDown>
        )}
      </div>
      <div className="options" onClick={onOpenSideBar}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </div>
    </div>
  )
}
export default AssetsTableRow
