import React from 'react'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons'
import { Button, DropDown } from '../index'
import CalendarRange from '../CalendarRange'
import { FILTER_TYPES } from '../../config/constants'

const AssetTrackingFilters = ({ onChange, activeFilter }) => (
  <div className="filters">
    <h6>Filters</h6>
    <DropDown
      dropDownCustomClass="calendar-dropdown shadow"
      openComponent={() => (
        <Button variant={activeFilter === FILTER_TYPES.RANGE ? 'primary' : 'secondary'} className="calendar-button" label=" Date" icon={faCalendarAlt} />
      )}
    >
      <CalendarRange onChange={(date) => onChange(date, FILTER_TYPES.RANGE)} />
    </DropDown>
    <div className="btn-group quick-filters" role="group" aria-label="Basic example">
      <button onClick={() => onChange(null, FILTER_TYPES.DAYS7)}type="button" className={`btn btn-${activeFilter === FILTER_TYPES.DAYS7 ? 'primary' : 'secondary'}`}>7 days</button>
      <button onClick={() => onChange(null, FILTER_TYPES.DAYS14)}type="button" className={`btn btn-${activeFilter === FILTER_TYPES.DAYS14 ? 'primary' : 'secondary'}`}>14 days</button>
      <button onClick={() => onChange(null, FILTER_TYPES.DAYS30)}type="button" className={`btn btn-${activeFilter === FILTER_TYPES.DAYS30 ? 'primary' : 'secondary'}`}>30 days</button>
    </div>
    <hr/>
  </div>
)

export default AssetTrackingFilters
