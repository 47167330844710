export const DEFAULT_POOL_INTERVAL = 1000 * 30
export const TAG_TYPES_AND_TAGS_POOL_INTERVAL = 1000 * 10
export const DEFAULT_ERROR_TIMEOUT = 2000

export const TAGS_LENGTH_ERROR_MESSAGE = 'Please provide tag name'
export const ASSET_LENGTH_ERROR_MESSAGE = 'Please provide asset name'

export const TITLE_SAVE_CHANGES = 'Save changes'
export const TITLE_CANCEL = 'Cancel'
export const TITLE_DELETE = 'Delete'
export const TITLE_EDIT = 'Edit'
export const TITLE_MANAGE_TAGS = 'Manage Tags'
export const TITLE_CLOSE = 'Close'
export const TITLE_CLEAR = 'Clear'

export const MIN_LENGTH = 2
export const MIN_PRESS_COUNT = 2
export const BACKSPACE_KEY_CODE = 8

export const INLINE_OPERATOR_MODES = {
  EDITABLE: 'editable',
  DELETABLE: 'deletable'
}

export const DEFAULT_DATE_FORMAT = 'MM/DD/YY h:mm a'

export const US_DATE_FORMAT = 'YYYY-MM-DD'

export const FILTER_TYPES = {
  RANGE: 'range',
  DAYS7: 'days7',
  DAYS14: 'days14',
  DAYS30: 'days30'
}
