import React from 'react'
import { Mutation, Query } from 'react-apollo'
import { DEFAULT_POOL_INTERVAL } from '../../config/constants'
import createTagMutation from './createTag.graphql'
import query from './tags.graphql'
import Tags from './Tags'
import { Loader } from '../index'

export default ({ tagType, ...rest }) => (
  <Query
    query={query}
    pollInterval={DEFAULT_POOL_INTERVAL}
    variables={{ tagType }}
  >
    {props =>
      !props.loading ? (
        <Mutation mutation={createTagMutation}>
          {createMutation => (
            <Tags
              createMutation={createMutation}
              tags={(props.data && props.data.tags) || []}
              tagType={tagType}
              {...rest}
            />
          )}
        </Mutation>
      ) : (
        <Loader />
      )
    }
  </Query>
)
