import React from 'react'
import { Query } from 'react-apollo'
import AssetsWithMutation from '../AssetsWithMutation'
import AssetsSidebarContent from './AssetsSidebarContent'
import assetQuery from './asset.graphql'
import { Loader } from '../index'

export default props => {
  const renderSidebarContent = otherProps => {
    return !otherProps.loading ? (
      <AssetsWithMutation>
        {data => <AssetsSidebarContent {...data} {...otherProps} />}
      </AssetsWithMutation>
    ) : (
      <Loader />
    )
  }

  return props.id ? (
    <Query query={assetQuery} variables={{ id: props.id }}>
      {({ data: { asset } = {}, loading }) => {
        return renderSidebarContent({ ...props, asset, loading })
      }}
    </Query>
  ) : (
    renderSidebarContent(props)
  )
}
